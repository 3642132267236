import React, { useEffect, useState } from 'react';
import { Button, Group, Box, Textarea, createListCollection } from '@chakra-ui/react';
import { Formik, useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import api from '../../../api';
import { actions } from '../../../reducers/reducer_establishment_details';
import { useDispatch } from 'react-redux';

import { Alert } from '../../ui/alert';
import { Field } from '@/components/ui/field';
import {
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from '@/components/ui/dialog';
import { toaster } from '@/components/ui/toaster';
import {
  SelectRoot,
  SelectLabel,
  SelectTrigger,
  SelectValueText,
  SelectContent,
  SelectItem,
} from '@/components/ui/select';

const { requestEstblishmentDetailsEditStatus } = actions;

const statusOptCollection = createListCollection({
  items: [
    { label: 'Approved', value: 'Approved' },
    { label: 'Rejected', value: 'Rejected' },
  ],
});

const ChangeStatus = ({ establishmentId }) => {
  const dispatch = useDispatch();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const chnageStatusHandler = async values => {
    try {
      setIsLoading(true);
      const response = await api.patch(`/op-establishment-detail/${establishmentId}`, {
        establishment: values,
      });

      if (response.ok) {
        dispatch(requestEstblishmentDetailsEditStatus(await response.json()));
        toaster.create({
          title: 'Status Changed Successfully',
          status: 'success',
        });
      }
    } catch (error) {
      toaster.create({
        title: 'Error',
        description: error.message,
        status: 'error',
      });
    } finally {
      setIsLoading(false);
      setIsOpenPopup(false);
    }
  };

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } = useFormik({
    initialValues: {
      status: '',
      reason: '',
    },
    onSubmit: chnageStatusHandler,
    validationSchema: Yup.object().shape({
      status: Yup.string().required('Status is Required'),
      reason: Yup.string().when('status', {
        is: 'Rejected',
        then: Yup.string().required('Reason is Required'),
      }),
    }),
  });

  useEffect(() => {
    if (!isOpenPopup) {
      setFieldValue('status', '');
      setFieldValue('reason', '');
    }
  }, [isOpenPopup, setFieldValue]);

  return (
    <>
      <Button variant="solid" colorScheme="actionSecondary" onClick={() => setIsOpenPopup(true)}>
        Change Status
      </Button>
      {isOpenPopup && (
        <DialogRoot open={isOpenPopup} onClose={() => setIsOpenPopup(false)} closeOnOverlayClick={false}>
          <DialogContent maxW="min(700px, 80%)">
            <DialogHeader borderBottomWidth="1px" fontSize="20px">
              Change Status
            </DialogHeader>
            <DialogCloseTrigger asChild onClick={() => setIsOpenPopup(false)} />
            <Formik>
              <form onSubmit={handleSubmit}>
                <DialogBody padding="22px">
                  <Box flexGrow={1}>
                    <SelectRoot
                      collection={statusOptCollection}
                      value={[values.status]}
                      onValueChange={e => setFieldValue('status', e.value[0])}
                    >
                      <SelectLabel>Status</SelectLabel>
                      <SelectTrigger>
                        <SelectValueText placeholder="Select" />
                      </SelectTrigger>
                      <SelectContent zIndex="10000">
                        {statusOptCollection.items.map(opt => (
                          <SelectItem item={opt} key={opt.value}>
                            {opt.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </SelectRoot>
                    {errors.status && touched.status && <Alert status="error" title={errors.status} />}
                  </Box>
                  {values.status === 'Rejected' && (
                    <Box flexGrow={1} marginTop="25px">
                      <Field label="Reason" errorText={errors.reason} invalid={errors.reason && touched.reason}>
                        <Textarea height="120px" value={values.reason} onChange={handleChange} name="reason" />
                      </Field>
                    </Box>
                  )}
                </DialogBody>
                <DialogFooter borderTopWidth="1px" marginTop="26px" padding="16px 22px">
                  <Group marginLeft="auto">
                    <Button
                      type="button"
                      fontSize="16px"
                      width="126px"
                      height="43px"
                      backgroundColor="gray.200" color="#333"
                      onClick={() => setIsOpenPopup(false)}
                    >
                      Close
                    </Button>
                    &nbsp;
                    <Button
                      type="submit"
                      width="126px"
                      height="43px"
                      colorScheme="actionPrimary"
                      marginLeft="10px"
                      fontSize="16px"
                      loadingText="Saving..."
                      loading={isLoading}
                    >
                      Save
                    </Button>
                  </Group>
                </DialogFooter>
              </form>
            </Formik>
          </DialogContent>
        </DialogRoot>
      )}
    </>
  );
};

ChangeStatus.propTypes = {
  establishmentId: PropTypes.number.isRequired,
  estDetail: PropTypes.shape({
    name: PropTypes.string,
    status: PropTypes.string,
    reason: PropTypes.string,
    listed_status: PropTypes.string,
    est: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    est_supported_options: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
  }),
};

export default ChangeStatus;
