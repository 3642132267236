import { get, isEmpty } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import React, { Component } from 'react';
import Datetime from '@/components/core/DatePicker';
// Local Deps
import './RenderInputComponentByType.scss';
import SelectField from '../basic/SelectField';
import PropTypes from 'prop-types';

export default class RenderInputComponentByType extends Component {
  onMultiSelectChange = (fieldName, inputType, selectedValues, multiSelectOptions) => {
    const allValues = cloneDeep(multiSelectOptions);
    // removing custom defined 'all' key
    allValues.pop('all');
    if (selectedValues && selectedValues.find(o => o.value === 'all')) {
      this.props.OnFieldValueChange(fieldName, inputType, allValues);
    } else {
      this.props.OnFieldValueChange(fieldName, inputType, selectedValues);
    }
  };

  render() {
    const { inputType, sourceData, fieldValue, isDisabled, name, OnFieldValueChange } = this.props;
    let multiSelectOptions = [];
    if (sourceData && inputType === 'multi-select') {
      multiSelectOptions = sourceData.reduce((agg, obj) => {
        agg.push({
          label: Object.keys(obj)[0],
          value: Object.values(obj)[0],
        });
        return agg;
      }, []);
      multiSelectOptions.push({ value: 'all', label: 'Select All' });
    }

    switch (inputType) {
      case 'multi-select':
        return (
          <SelectField
            className="render-input-component"
            disabled={isDisabled}
            name={name}
            defaultValue={fieldValue}
            options={multiSelectOptions}
            isMulti
            style={{ width: '300px' }}
            menuStyles={{
              zIndex: '9999 !important',
            }}
            onChange={e => {
              this.onMultiSelectChange(name, inputType, e, multiSelectOptions);
            }}
          />
        );
      case 'text':
        return (
          <input
            type="text"
            disabled={isDisabled}
            className="form-control"
            name={name}
            value={fieldValue}
            onChange={e => {
              OnFieldValueChange(name, inputType, get(e, 'target.value', ''));
            }}
            style={{ width: '300px', borderColor: '#d9d9d9' }}
          />
        );
      case 'boolean':
        return (
          <SelectField
            disabled={isDisabled}
            name={name}
            defaultValue={fieldValue}
            options={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
            onChange={e => {
              OnFieldValueChange(name, inputType, get(e, 'target.value', ''));
            }}
            style={{ width: '300px' }}
          />
        );
      case 'date':
        return (
          <Datetime
            id={name}
            inputProps={{
              className: 'form-control m-input',
              disabled: isDisabled,
            }}
            closeOnSelect
            value={fieldValue instanceof Array ? fieldValue[0] : fieldValue}
            onChange={e => {
              const numberDatePattern = /^[0-9\-/]+$/;
              // eslint-disable-next-line no-underscore-dangle
              if (e._isAMomentObject || numberDatePattern.test(e) || isEmpty(e)) {
                // eslint-disable-next-line no-underscore-dangle
                OnFieldValueChange(name, inputType, e._isAMomentObject ? e.format('YYYY-MM-DD') : e);
              } else {
                OnFieldValueChange(name, inputType, '');
              }
            }}
            timeFormat={false}
            dateFormat="YYYY-MM-DD"
            style={{ width: '300px' }}
          />
        );
      default:
        return null;
    }
  }
}

RenderInputComponentByType.propTypes = {
  inputType: PropTypes.string.isRequired,
  sourceData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ),
  fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.array, PropTypes.object]),
  isDisabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  OnFieldValueChange: PropTypes.func.isRequired,
};
