import React, { useEffect, useState } from 'react';
import { Box, Button, Group, Input, Flex } from '@chakra-ui/react';
import { Formik, useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import api from '../../../api';
import Constants from '../../../Constants';
import countryCodes from '../../../shared/countryCodes';
import CustomMultiSelect from '../../basic/CustomMultiSelect';
import {
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from '@/components/ui/dialog';
import { Alert } from '../../ui/alert';
import { Field } from '@/components/ui/field';
import { NativeSelectField, NativeSelectRoot } from '@/components/ui/native-select';
import { toaster } from '@/components/ui/toaster';

const UpdateFacilityInfo = ({ isOpenPopup, setIsOpenPopup, estId, editItem, updateList, addressData }) => {
  const [isLoading, setIsLoading] = useState(false);

  const updateInfo = async values => {
    const payload = {
      screen: 'facility_info',
      fields_info: [
        {
          ...(editItem?.id && { id: editItem?.id }),
          address1: values.address1,
          address2: values.address2,
          city: values.city,
          state: values.state,
          country: values.country,
          zip: values.zip,
          staff: +values.staff,
          daily_production: values.daily_production,
          daily_boining: values.daily_boining,
          boning_process: values.boning_process?.map(item => item.value),
          stunning_method: values.stunning_method,
          production_days: values.production_days?.map(item => item.value),
          category: values.category?.map(item => item.value),
          shift_times: values.shift_times?.map(item => item.value),
          raw_material: values.raw_material?.map(item => item.value),
          //   org_chart: orgChartLink.file_url,
          //   religious_certificates: rCertLinks.map(link => (link?.file_url ? link?.file_url : '')),
        },
      ],
    };

    try {
      setIsLoading(true);
      if (estId) {
        const response = await api.patch(`/op-establishment/${estId}`, payload);
        if (response.ok) {
          //   const json = await response.json();
          //   setData(prevState => prevState.map(item => (item.id === json.id ? json : item)));
          updateList(allList => {
            const updatedList = allList.map(item => {
              if (item.id === estId) {
                return {
                  ...item,
                  address: {
                    line1: payload.fields_info[0]?.address1,
                    line2: payload.fields_info[0]?.address2,
                    city: payload.fields_info[0]?.city,
                    state: payload.fields_info[0]?.state,
                    country: payload.fields_info[0]?.country,
                    post_code: payload.fields_info[0]?.zip,
                  },
                  facility_information: { ...item.facility_information, ...payload.fields_info[0] },
                };
              }
              return item;
            });
            return updatedList;
          });

          toaster.create({
            title: 'Record updated successfully',
            status: 'success',
          });
        }
      }
    } catch (error) {
      toaster.create({
        title: error.message,
        status: 'error',
      });
    } finally {
      setIsLoading(false);
      setIsOpenPopup(false);
    }
  };

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } = useFormik({
    initialValues: {
      address1: addressData?.line1 || '',
      address2: addressData?.line2 || '',
      city: addressData?.city || '',
      state: addressData?.state || '',
      zip: addressData?.post_code || '',
      country: addressData?.country || '',
      daily_production: editItem?.daily_production || '',
      daily_boining: editItem?.daily_boining || '',
      boning_process: editItem?.boning_process || [],
      stunning_method: editItem?.stunning_method || '',
      production_days: editItem?.production_days || [],
      category: editItem?.category || [],
      shift_times: editItem?.shift_times || [],
      staff: editItem?.staff || 0,
      raw_material: editItem?.raw_material || [],
    },
    onSubmit: updateInfo,
    validationSchema: Yup.object().shape({
      address1: Yup.string().required('Address is required'),
      city: Yup.string().required('City is required'),
      state: Yup.string().required('State is required'),
      zip: Yup.string().required('Postal code is required'),
      country: Yup.string().required('Country is required'),
      daily_production: Yup.string(),
      daily_boining: Yup.string(),
      boning_process: Yup.array(),
      stunning_method: Yup.string(),
      production_days: Yup.array(),
      category: Yup.array(),
      shift_times: Yup.array(),
      staff: Yup.number().min(0, 'Staff must be 0 or a positive number').integer('Staff must be an integer'),
      raw_material: Yup.array(),
    }),
  });

  useEffect(() => {
    if (editItem) {
      setFieldValue('address1', editItem.address1);
      setFieldValue('address2', editItem.address2);
      setFieldValue('city', editItem.city);
      setFieldValue('state', editItem.state);
      setFieldValue('zip', editItem.zip);
      setFieldValue('country', editItem.country);
      setFieldValue('daily_production', editItem.daily_production);
      setFieldValue('daily_boining', editItem.daily_boining);
      setFieldValue(
        'boning_process',
        editItem.boning_process?.map(item => ({ value: item, label: item }))
      );
      setFieldValue('stunning_method', editItem.stunning_method);
      setFieldValue(
        'production_days',
        editItem.production_days?.map(item => ({ value: item, label: item }))
      );
      setFieldValue(
        'category',
        editItem.category?.map(item => ({ value: item, label: item }))
      );
      setFieldValue(
        'shift_times',
        editItem.shift_times?.map(item => ({ value: item, label: item }))
      );
      setFieldValue('staff', editItem.staff);
      setFieldValue(
        'raw_material',
        editItem.raw_material?.map(item => ({ value: item, label: item }))
      );
    }
  }, [editItem]);

  return (
    <DialogRoot open={isOpenPopup} onClose={() => setIsOpenPopup(false)} closeOnOverlayClick={false}>
      <DialogContent maxW="min(870px, 80%)">
        <DialogHeader borderBottomWidth="1px" fontSize="20px">
          Update Facility Information
        </DialogHeader>
        <DialogCloseTrigger onClick={() => setIsOpenPopup(false)} />
        <Formik>
          <form onSubmit={handleSubmit}>
            <DialogBody padding="22px">
              <Box flexGrow={1} marginBottom="20px">
                <Field label="Address*" errorText={errors.address1} invalid={errors.address1 && touched.address1}>
                  <Input name="address1" value={values.address1} onChange={handleChange} />
                </Field>
              </Box>
              <Box flexGrow={1} marginBottom="20px">
                <Field
                  label="Address line 2"
                  errorText={errors.address2}
                  invalid={errors.address2 && touched.address2}
                >
                  <Input name="address2" value={values.address2} onChange={handleChange} />
                </Field>
              </Box>

              <Flex gap="20px" css={{ gap: '20px' }}>
                <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <Field label="City*" errorText={errors.city} invalid={errors.city && touched.city}>
                    <Input name="city" value={values.city} onChange={handleChange} />
                  </Field>
                </Box>
                <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <Field label="State*" errorText={errors.state} invalid={errors.state && touched.state}>
                    <Input name="state" value={values.state} onChange={handleChange} />
                  </Field>
                </Box>
              </Flex>

              <Flex gap="20px" css={{ gap: '20px' }}>
                <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <Field label="Postal Code*" errorText={errors.zip} invalid={errors.zip && touched.zip}>
                    <Input name="zip" value={values.zip} onChange={handleChange} />
                  </Field>
                </Box>
                <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <Field label="Country*" errorText={errors.country} invalid={errors.country && touched.country}>
                    <NativeSelectRoot>
                      <NativeSelectField
                        zIndex="10000"
                        placeholder="Select"
                        value={values.country}
                        onChange={e => setFieldValue('country', e.currentTarget.value)}
                      >
                        {countryCodes.map(country => (
                          <option value={country.dial_code} key={`${country.name} (${country.dial_code})`}>
                            {`${country.name} (${country.dial_code})`}
                          </option>
                        ))}
                      </NativeSelectField>
                    </NativeSelectRoot>
                  </Field>

                  {errors.country && touched.country && <Alert status="error" title={errors.country} />}
                </Box>
              </Flex>

              <Flex gap="20px" css={{ gap: '20px' }}>
                <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <Field
                    label="Daily Production"
                    errorText={errors.daily_production}
                    invalid={errors.daily_production && touched.daily_production}
                  >
                    <Input name="daily_production" value={values.daily_production} onChange={handleChange} />
                  </Field>
                </Box>
                <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <Field
                    label="Daily Boning"
                    errorText={errors.daily_boining}
                    invalid={errors.daily_boining && touched.daily_boining}
                  >
                    <Input name="daily_boining" value={values.daily_boining} onChange={handleChange} />
                  </Field>
                </Box>
              </Flex>

              <Flex gap="20px" css={{ gap: '20px' }}>
                <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <CustomMultiSelect
                    label="Category"
                    value={values.category}
                    options={Constants.category}
                    onChange={value => {
                      setFieldValue('category', value);
                    }}
                  />
                  {errors.category && touched.category && <Alert status="error">{errors.category}</Alert>}
                </Box>
                <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <CustomMultiSelect
                    label="What is your boning process"
                    value={values.boning_process}
                    options={Constants.boningProcesses}
                    onChange={value => {
                      setFieldValue('boning_process', value);
                    }}
                  />
                  {errors.boning_process && touched.boning_process && (
                    <Alert status="error" title={errors.boning_process} />
                  )}
                </Box>
              </Flex>

              <Flex gap="20px" css={{ gap: '20px' }}>
                <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <Field
                    label="Method of Stunning"
                    errorText={errors.stunning_method}
                    invalid={errors.stunning_method && touched.stunning_method}
                  >
                    <Input name="stunning_method" value={values.stunning_method} onChange={handleChange} />
                  </Field>
                </Box>
                <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <CustomMultiSelect
                    label="Production Days"
                    value={values.production_days}
                    options={Constants.days}
                    onChange={value => {
                      setFieldValue('production_days', value);
                    }}
                  />
                  {errors.production_days && touched.production_days && (
                    <Alert status="error" title={errors.production_days} />
                  )}
                </Box>
              </Flex>

              <Flex gap="20px" css={{ gap: '20px' }}>
                <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <CustomMultiSelect
                    label="Number of shifts"
                    value={values.shift_times}
                    options={Constants.shifts}
                    onChange={value => {
                      setFieldValue('shift_times', value);
                    }}
                  />
                  {errors.shift_times && touched.shift_times && <Alert status="error" title={errors.shift_times} />}
                </Box>
                <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <Field label="Number of Staff" errorText={errors.staff} invalid={errors.staff && touched.staff}>
                    <Input name="staff" type="number" value={values.staff} onChange={handleChange} />
                  </Field>
                </Box>
              </Flex>

              <Flex gap="20px" css={{ gap: '20px' }}>
                <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                  <CustomMultiSelect
                    label="What raw materials does your facility supply"
                    value={values.raw_material}
                    options={Constants.rawMaterials}
                    onChange={value => {
                      setFieldValue('raw_material', value);
                    }}
                  />
                  {errors.raw_material && touched.raw_material && <Alert status="error" title={errors.raw_material} />}
                </Box>
              </Flex>
            </DialogBody>
            <DialogFooter borderTopWidth="1px" padding="16px 22px">
              <Group marginLeft="auto">
                <Button
                  type="button"
                  fontSize="16px"
                  width="126px"
                  height="43px"
                  backgroundColor="gray.200"
                  color="#333"
                  onClick={() => setIsOpenPopup(false)}
                >
                  Close
                </Button>
                &nbsp;
                <Button
                  type="submit"
                  width="126px"
                  height="43px"
                  colorScheme="actionPrimary"
                  marginLeft="10px"
                  fontSize="16px"
                  loadingText="Saving..."
                  loading={isLoading}
                >
                  Save
                </Button>
              </Group>
            </DialogFooter>
          </form>
        </Formik>
      </DialogContent>
    </DialogRoot>
  );
};

UpdateFacilityInfo.propTypes = {
  isOpenPopup: PropTypes.bool,
  setIsOpenPopup: PropTypes.func,
  estId: PropTypes.number,
  editItem: PropTypes.shape({}) || null,
  updateList: PropTypes.func,
  addressData: PropTypes.shape({}) || null,
};
export default UpdateFacilityInfo;
