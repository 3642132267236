import { sum } from 'd3';
import { formatPercent, formatSI } from '../modules/format';
import { isOrderDomestic, isOrderAngus } from '../modules/order-utils';
import { ANGUS, FRESH_FROZEN } from '../slices/markets/marketsConfig';
import { MARKETS_VOLUME_TABLE_COLUMNS } from './marketsVolumeTableColumns';
import { MARKET_FOCUS } from '../modules/const';

// TODO: assign enums for grouping

export const VOLUME_GROUPINGS = {
  PRICING: {
    key: 'PRICING',

    title: 'Cost',
    label: 'Price type',
    getStatisticValue: orders => sum(orders, d => d.buy_quantity * d.sell_price_per_unit),
    prefix: '$',
    suffix: null,
    formatter: formatSI(2, 2),
    precision: 2,

    tableColumns: [
      MARKETS_VOLUME_TABLE_COLUMNS.VOLUME,
      MARKETS_VOLUME_TABLE_COLUMNS.VOLUME_PERCENT,
      MARKETS_VOLUME_TABLE_COLUMNS.COST,
      MARKETS_VOLUME_TABLE_COLUMNS.AVERAGE_CL,
    ],

    rowGroupingKey: 'order_type',
  },
  PRICING_PERFORMANCE: {
    key: 'PRICING_PERFORMANCE',

    title: 'Pricing',
    label: 'Pricing & Performance',
    getStatisticValue: orders => sum(orders, d => d.buy_quantity * d.sell_price_per_unit),
    prefix: '$',
    suffix: null,
    formatter: formatSI(2, 2),
    precision: 2,

    tableColumns: [
      MARKETS_VOLUME_TABLE_COLUMNS.VOLUME,
      MARKETS_VOLUME_TABLE_COLUMNS.VOLUME_PERCENT,
      MARKETS_VOLUME_TABLE_COLUMNS.COST,
      MARKETS_VOLUME_TABLE_COLUMNS.AVERAGE_CL,
      MARKETS_VOLUME_TABLE_COLUMNS.WEIGHTED_AVERAGE_PRICE,
      MARKETS_VOLUME_TABLE_COLUMNS.PERFORMANCE_AGAINST_USDA,
    ],

    rowGroupingKey: 'order_type',
  },
  PRODUCT: {
    key: 'PRODUCT',

    title: 'Product',
    label: 'Fresh v Frozen',
    getStatisticValue: (orders, volumeTotal) => {
      return (
        sum(
          orders.filter(d => d.fresh_frozen === FRESH_FROZEN.FRESH),
          d => d.buy_quantity
        ) / volumeTotal
      );
    },
    prefix: null,
    suffix: 'fresh',
    formatter: formatPercent(0),
    precision: 0,

    tableColumns: [
      MARKETS_VOLUME_TABLE_COLUMNS.VOLUME,
      MARKETS_VOLUME_TABLE_COLUMNS.VOLUME_PERCENT,
      MARKETS_VOLUME_TABLE_COLUMNS.SOURCES,
    ],

    rowGroupingKey: 'fresh_frozen',
  },
  ANGUS: {
    key: 'ANGUS',

    title: 'Angus',
    label: 'Angus',
    getStatisticValue: (orders, volumeTotal) => {
      return sum(orders.filter(isOrderAngus), d => d.buy_quantity) / volumeTotal;
    },
    prefix: null,
    suffix: '',
    formatter: formatPercent(0),
    precision: 0,

    tableColumns: [
      MARKETS_VOLUME_TABLE_COLUMNS.VOLUME,
      MARKETS_VOLUME_TABLE_COLUMNS.VOLUME_PERCENT,
      MARKETS_VOLUME_TABLE_COLUMNS.COST,
      MARKETS_VOLUME_TABLE_COLUMNS.AVERAGE_CL,
    ],

    rowGroupingFunc: order => (isOrderAngus(order) ? ANGUS.ANGUS : ANGUS.NOT_ANGUS),
  },
  SOURCING: {
    key: 'SOURCING',

    title: 'Sourcing',
    label: 'Sourcing',
    getStatisticValue: (orders, volumeTotal, activeMarket) => {
      return sum(orders.filter(isOrderDomestic(activeMarket)), d => d.buy_quantity) / volumeTotal;
    },
    prefix: null,
    // eslint-disable-next-line no-template-curly-in-string
    suffix: '${market}',
    formatter: formatPercent(0),
    precision: 0,

    tableColumns: [
      MARKETS_VOLUME_TABLE_COLUMNS.VOLUME,
      MARKETS_VOLUME_TABLE_COLUMNS.VOLUME_PERCENT,
      MARKETS_VOLUME_TABLE_COLUMNS.COST,
      MARKETS_VOLUME_TABLE_COLUMNS.AVERAGE_CL,
    ],
    rowGroupingKey: 'packer_plant_country',
  },
  COUNTRY: {
    key: 'COUNTRY',

    title: 'Country',
    label: 'Country',
    getStatisticValue: () => null,
    prefix: null,
    suffix: null,
    formatter: formatPercent(0),
    precision: 0,
    onlyShowForMarketFocus: [MARKET_FOCUS.EXPORT],

    tableColumns: [
      MARKETS_VOLUME_TABLE_COLUMNS.VOLUME,
      MARKETS_VOLUME_TABLE_COLUMNS.COST,
      MARKETS_VOLUME_TABLE_COLUMNS.WEIGHTED_AVERAGE_PRICE,
    ],

    rowGroupingKey: 'grinder_country',
    groupRowOverride: (rows, orders, options, { activeMarket, rawData }) => {
      const newRows = rows.slice();

      if (options['COUNTRY::domestic']) {
        const domesticOrders = rawData.orders[MARKET_FOCUS.DOMESTIC] || [];
        const domesticRow = {
          key: activeMarket.key,
          cost: sum(domesticOrders, o => o.sell_price_per_unit * o.buy_quantity),
          volume: sum(domesticOrders, o => o.buy_quantity),
          hidePercent: true,
        };
        newRows.unshift(domesticRow);
      }

      return newRows;
    },

    options: [
      {
        type: 'checkbox',
        key: 'COUNTRY::domestic',
        label: 'Compare with domestic',
        default: false,
      },
    ],
  },
};

export default VOLUME_GROUPINGS;
