import React from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa6';
import { mean, sum } from 'd3';
import { isSameDay } from 'date-fns';

import { calculate76LeanWeightedAveragePrice } from '../modules/data/beef-weighted-average';
import { formatMarketQuantity, formatPercent, formatSI } from '../modules/format';
import { uniq } from '../modules/utils';
// import store from '../store';

// const getProductSpecification = () => (store?.getState()?.user?.organisation?.name === 'Inspire Brands' ? 'VL' : 'CL');
// ende TODO Remove only for demo

export const MARKETS_VOLUME_TABLE_COLUMNS = {
  VOLUME: {
    key: 'volume',
    getTitle: market => `Volume (${market.quantityUnit})`,
    renderValue: (value, market) => formatMarketQuantity(market, 1)(value),
    getRowValue: orders => sum(orders, o => o.buy_quantity),
  },
  VOLUME_PERCENT: {
    key: 'volumePercent',
    getTitle: () => 'Volume (%)',
    renderValue: value => formatPercent(1)(value),
    getRowValue: (orders, volumeTotal) => sum(orders, o => o.buy_quantity) / volumeTotal,
  },
  COST: {
    key: 'cost',
    getTitle: () => 'Cost',
    renderValue: value => `$${formatSI(2, 2)(value)}`,
    getRowValue: orders => sum(orders, o => o.buy_quantity * o.sell_price_per_unit),
  },
  AVERAGE_CL: {
    key: 'averageCL',
    // getTitle: () => `${getProductSpecification()} avg`,
    getTitle: () => `VL/CL avg`,
    // renderValue: value => `${formatSI(0)(value)}${getProductSpecification()} avg`,
    renderValue: value => `${formatSI(0)(value)}VL/CL avg`,
    getRowValue: orders => mean(orders, o => o.cl),
  },
  WEIGHTED_AVERAGE_PRICE: {
    key: 'weightedAveragePricePerUnit',
    getTitle: () => 'Cost per Unit',
    renderValue: value => `$${formatSI(2, 2)(value)}/unit`,
    getRowValue: orders => calculate76LeanWeightedAveragePrice(orders, 'buy_quantity'),
  },
  PERFORMANCE_AGAINST_USDA: {
    key: 'performanceAgainstUsda',
    getTitle: () => 'Performance',
    renderValue: value => {
      let icon;
      if (value > 0) {
        icon = <FaCaretUp style={{ color: 'green' }} />;
      } else if (value < 0) {
        icon = <FaCaretDown style={{ color: 'red' }} />;
      } else {
        icon = '-';
      }
      return (
        <span>
          {icon}
          {formatPercent(2)(value)} USDA
        </span>
      );
    },
    getRowValue: (orders, volumeTotal, rowKey, usdaPrices) => {
      const orderPerformances = orders.map(order => {
        const usdaPricing = usdaPrices.find(price => isSameDay(order.order_date, price.date));
        if (!usdaPricing) return null;
        const usdaGroupPrice = usdaPricing[rowKey.toLowerCase()];
        const leanAdjustedOrderPrice = (order.sell_price_per_unit / order.cl) * 76;

        // Multiplying by quantity to allow for small orders to not have more weight than huge orders
        return ((usdaGroupPrice - leanAdjustedOrderPrice) / usdaGroupPrice) * order.buy_quantity;
      });

      // Divide by the sum of quantities because we weighted by quantity when calculating performance.
      return sum(orderPerformances) / sum(orders, o => o.buy_quantity);
    },
  },
  SOURCES: {
    key: 'sources',
    getTitle: () => 'Source',
    renderValue: value => value.join('\n'),
    getRowValue: orders => {
      const sources = orders.map(d => d.grinder_country);
      return uniq(sources);
    },
  },
};
