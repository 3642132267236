import React, { useRef, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Box, Button } from '@chakra-ui/react';
import { DialogBackdrop, DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot } from '../ui/dialog';

const Confirm = ({ onConfirm, children, title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef();

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  return (
    <>
      <Box onClick={onOpen}>{children}</Box>
      <DialogRoot open={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <DialogBackdrop />
        <DialogContent>
          <DialogHeader fontSize="lg" fontWeight="bold">
            Please Confirm
          </DialogHeader>

          <DialogBody>{title}</DialogBody>

          <DialogFooter>
            <Button colorScheme="actionPrimary" variant="outline" ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="actionPrimary"
              onClick={() => {
                onConfirm();
                onClose();
              }}
              ml={3}
            >
              Confirm
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogRoot>
    </>
  );
};

Confirm.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default Confirm;
