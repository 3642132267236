import React, { useLayoutEffect, useRef, useState } from 'react';
import { Box, Button, Tabs } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

const TabButtons = ({ establishmentDetailList }) => {
  const tabList = useRef(null);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [clientWidth, setClientWidth] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleScrollRight = () => {
    const scrollAmount = tabList.current.clientWidth / 2;
    tabList.current.scrollBy({
      left: scrollAmount,
      behavior: 'smooth',
    });

    if (tabList.current.scrollWidth - tabList.current.clientWidth - tabList.current.scrollLeft < scrollAmount) {
      tabList.current.scrollLeft = tabList.current.scrollWidth - tabList.current.clientWidth;
    }

    setScrollLeft(tabList.current.scrollLeft);
  };

  const handleScrollLeft = () => {
    const scrollAmount = tabList.current.clientWidth / 2;
    tabList.current.scrollBy({
      left: -scrollAmount,
      behavior: 'smooth',
    });

    if (tabList.current.scrollLeft < scrollAmount) {
      tabList.current.scrollLeft = 0;
    }

    setScrollLeft(tabList.current.scrollLeft);
  };

  useLayoutEffect(() => {
    if (tabList.current?.scrollWidth && tabList.current?.clientWidth) {
      setScrollWidth(tabList.current?.scrollWidth);
      setClientWidth(tabList.current?.clientWidth);
      setScrollLeft(tabList.current?.scrollLeft);
    }
  }, [tabList.current?.scrollWidth, tabList.current?.clientWidth, tabList.current?.scrollLeft]);

  return (
    <Box display="flex">
      {scrollLeft > 0 && (
        <Button type="button" onClick={handleScrollLeft} marginRight="15px">
          <FaAngleLeft />
        </Button>
      )}

      <Tabs.List
        ref={tabList}
        variant="enclosed"
        borderBottomWidth="0px"
        overflowX="auto"
        overflowY="hidden"
        css={{
          '&::-webkit-scrollbar': {
            height: '0px',
            width: '0px',
          },
          '&::-webkit-scrollbar-track': {
            height: '0px',
            width: '0px',
            background: 'var(--chakra-colors-gray-100)',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'var(--chakra-colors-nav-scrollBarBackgroundColor)',
          },
        }}
      >
        {establishmentDetailList &&
          establishmentDetailList?.length > 0 &&
          establishmentDetailList?.map(estDetails => {
            return (
              <Tabs.Trigger
                key={estDetails.id}
                value={estDetails.id}
                _focus={{ outline: 'none' }}
                _selected={{
                  borderColor: 'actionSecondary.default',
                  borderBottomWidth: '2px',
                  color: 'actionSecondary.default',
                }}
                _hover={{
                  borderColor: 'actionSecondary.default',
                  borderBottomWidth: '2px',
                  color: 'actionSecondary.default',
                }}
                fontSize="14px"
                fontWeight="500"
                padding="9px 16px"
                textTransform="uppercase"
                whiteSpace="nowrap"
                boxSizing="border-box"
                marginBottom="0"
                flexShrink={0}
              >
                {estDetails.name}
              </Tabs.Trigger>
            );
          })}
      </Tabs.List>
      {scrollWidth > clientWidth && (
        <Button type="button" onClick={handleScrollRight} marginLeft="15px">
          <FaAngleRight />
        </Button>
      )}
    </Box>
  );
};

TabButtons.propTypes = {
  establishmentDetailList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
};

export default TabButtons;
