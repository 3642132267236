import React, { useState } from 'react';
import { Button, IconButton } from '@chakra-ui/react';
import { MdDelete } from 'react-icons/md';
import PropTypes from 'prop-types';
import { Tooltip } from '@/components/ui/tooltip';
import { DialogBackdrop, DialogBody, DialogContent, DialogFooter, DialogHeader, DialogRoot } from '../../ui/dialog';

const DeleteContact = ({ onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = React.useRef();

  return (
    <>
      <Tooltip content="Delete">
        <IconButton
          width="24px"
          height="24px"
          padding="0"
          minW="auto"
          border="1px solid #D9DADF"
          borderRadius="2px"
          color="#00000099"
          backgroundColor="#fff"
          onClick={() => setIsOpen(true)}
        >
          <MdDelete size={12} />
        </IconButton>
      </Tooltip>

      <DialogRoot open={isOpen} leastDestructiveRef={cancelRef} onClose={() => setIsOpen(false)}>
        <DialogBackdrop />
        <DialogContent>
          <DialogHeader fontSize="lg" fontWeight="bold">
            Delete Contact
          </DialogHeader>

          <DialogBody>Are you sure? You can&apos;t undo this action afterwards.</DialogBody>

          <DialogFooter>
            <Button ref={cancelRef} onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                onDelete();
                setIsOpen(false);
              }}
              ml={3}
            >
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </DialogRoot>
    </>
  );
};

DeleteContact.propTypes = {
  onDelete: PropTypes.func,
};

export default DeleteContact;
