import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from '../basic/Loading';
import { Navigate } from 'react-router';

class Logout extends Component {
  static propTypes = {
    onLogout: PropTypes.func,
    token: PropTypes.string,
    user: PropTypes.object,
  };

  componentDidMount() {
    const { onLogout, token } = this.props;
    onLogout(token);
  }

  render() {
    const { user } = this.props;
    if (user) {
      return <Loading />;
    }
    return <Navigate to="/login" replace />;
  }
}

export default Logout;
