import React, { Component } from 'react';
import OrderHistory from '../../containers/orders/OrderHistory';
import { fetch_pologs_history_tabs } from '../../actions/actions_pologs';
import CreatePurchaseOrderForm from '../../containers/orders/CreatePurchaseOrderForm';
import PurchaseOrder from '../../containers/orders/PurchaseOrder';
import OrderManagementTabList from '../orders/OrderManagementTabList';
import { Box, Tabs } from '@chakra-ui/react';
import Constants from '../../Constants';
import IUnleashClassFlagProvider from '../../components/unleash/UnleashClassFlagProvider';
import { getTenantFromDomain } from '../../helpers/tenant';

class OrderManagement extends Component {
  constructor(props) {
    super(props);
    const tenant = getTenantFromDomain();
    this.state = {
      active_type: 'order_history',
      poGridtoggleStatus: false,
      activeIndex: 0,
      tabs: [
        {
          type: 'order_history',
          name: 'order_history',
          label: 'Orders',
        },
        {
          type: 'single_order',
          name: 'single_order',
          label: 'USA Orders',
          disabledFlag: Constants.UNLEASH_FLAG.CHICAGO_ORDER,
        },
        {
          type: 'create_purchase_order',
          name: 'create_purchase_order',
          label: tenant.includes('cfc') ? 'Create new order' : 'AU/NZ Orders',
          disabledFlag: Constants.UNLEASH_FLAG.SYDNEY_ORDER,
        },
      ],
    };
  }

  retrieveTabs() {
    this.props.dispatch(fetch_pologs_history_tabs(this.props.user.token));
    this.setState({ active_type: 'polog' });
  }

  handleTabNavigation = (type, name) => {
    const { tabs } = this.state;
    const tabIndex = tabs.findIndex(tabItem => tabItem.name === name);
    this.setState({
      active_type: type,
      activeIndex: tabIndex,
    });
  };

  // Definition of handleToggleChange
  handleToggleChange = () => {
    this.setState(prevState => ({
      poGridtoggleStatus: !prevState.poGridtoggleStatus, // Toggle the status
    }));
  };

  render() {
    const { active_type, activeIndex } = this.state;
    const { tabs } = this.state;
    return (
      <Box mb={0} style={{ display: 'grid' }}>
        <Tabs.Root defaultValue="order_history" index={activeIndex}>
          <OrderManagementTabList
            tabs={tabs}
            handleNavigation={(type, name) => this.handleTabNavigation(type, name)}
            poGridtoggleStatus={this.state.poGridtoggleStatus}
            handleToggleChange={this.handleToggleChange}
          />
          <Box minH="xl" paddingX="0px">
            <Tabs.Content value="order_history" paddingX="0px" paddingY="26px">
              {active_type === 'order_history' && <OrderHistory poGridtoggleStatus={this.state.poGridtoggleStatus} />}
            </Tabs.Content>
            <Tabs.Content value="single_order" paddingX="22px">
              {active_type === 'single_order' && <PurchaseOrder />}
            </Tabs.Content>
            <Tabs.Content value="create_purchase_order" paddingX="42px" paddingY="26px">
              {active_type === 'create_purchase_order' && <CreatePurchaseOrderForm />}
            </Tabs.Content>
          </Box>
        </Tabs.Root>
      </Box>
    );
  }
}

const OrderManagementUnleashController = props => {
  return (
    <>
      <IUnleashClassFlagProvider
        flagName={Constants.UNLEASH_FLAG.ORDER_MANAGEMENT}
        show={<OrderManagement {...props} />}
        hide={<h1>Feature Not Enabled</h1>}
      />
    </>
  );
};

export default OrderManagementUnleashController;
