import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@chakra-ui/react';
import { MdDownload } from 'react-icons/md';
import { Tooltip } from '@/components/ui/tooltip';
import { previewFromS3Link } from '../../../helpers/downloads';
import { downloadFile } from '../../../utils';

const DownloadResource = ({ url }) => {
  const [downloadLoading, setDownloadLoading] = useState(false);

  const downloadFileHandler = async _url => {
    setDownloadLoading(true);
    const fName = _url?.split('/').pop();
    const signedUrl = await previewFromS3Link(_url);
    await downloadFile(signedUrl, fName);
    setDownloadLoading(false);
  };

  return (
    <>
      <Tooltip content="Download">
        <IconButton
          width="24px"
          height="24px"
          padding="0"
          minW="auto"
          border="1px solid #D9DADF"
          borderRadius="2px"
          color="#00000099"
          backgroundColor="#fff"
          loading={downloadLoading}
          onClick={() => downloadFileHandler(url)}
        >
          <MdDownload size={12} />
        </IconButton>
      </Tooltip>
    </>
  );
};

DownloadResource.propTypes = {
  url: PropTypes.string,
};

export default DownloadResource;
