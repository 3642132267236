import React, { Component } from 'react';
import { Text, VStack, Flex, Input, Textarea, HStack, Button, IconButton, Box, Group, Grid } from '@chakra-ui/react';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { MdArrowBack, MdDeleteOutline } from 'react-icons/md';
// Local Deps
import { fetchRoles } from '../../../actions/actions_roles';
import { addReport, fetchReportCategories, updateReport } from '../../../actions/actions_self_service_reports';
import { fetchUsers } from '../../../actions/actions_users';
import SelectField from '../../basic/SelectField';
import CustomFormControl from '../../core/CustomFormControl';
import DashboardSectionTile from '../../core/DashboardSectionTile';
import { formatReportConfig, prepareSelfServiceReportPayload } from '../helper';
import { Alert } from '@/components/ui/alert';
import { Tooltip } from '@/components/ui/tooltip';
import withRouter from '@/components/utilities/withRouter';

class SelfServiceReport extends Component {
  static propTypes = {
    reportConfig: PropTypes.shape({
      name: PropTypes.string,
      category: PropTypes.string,
      description: PropTypes.string,
      params: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          param_type: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
          default: PropTypes.string,
          options: PropTypes.string,
          param_query_options: PropTypes.string,
        })
      ),
      permissions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        })
      ),
      error: PropTypes.arrayOf(
        PropTypes.shape({
          message: PropTypes.string,
          code: PropTypes.string,
        })
      ),
    }),
    dispatch: PropTypes.func,
    match: PropTypes.shape({ params: PropTypes.shape({ reportId: PropTypes.string }) }),
    history: PropTypes.shape({
      action: PropTypes.string,
      block: PropTypes.func,
      createHref: PropTypes.func,
      go: PropTypes.func,
      goBack: PropTypes.func,
      goForward: PropTypes.func,
      push: PropTypes.func,
    }),
    navigate: PropTypes.func,
    user: PropTypes.shape({ token: PropTypes.string }),
    fetchReportConfig: PropTypes.func,
    categories: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
  };

  state = {
    name: '',
    category: '',
    description: '',
    permittedRoles: [],
    permittedUsers: [],
    sql: '',
    params: [
      {
        name: '',
        param_type: [],
        default: '',
        options: '',
        param_query_options: '',
      },
    ],
    isEdit: false,
    isSubmitted: false,
    isSubmissionSuccessful: false,
    missingFieldsWarning: null,
  };

  breadcrumbsConfig = [
    {
      url: '/self-service-reports',
      component: 'ReportsListComponent',
      onClick: () => {
        this.props.navigate('/self-service-reports');
      },
      title: 'Back to list',
      activeIndex: 0,
    },
    {
      url: '/self-service-report',
      component: 'selfServiceReportComponent',
      activeIndex: 1,
      title: 'Report',
    },
  ];

  componentDidMount() {
    const {
      dispatch,
      match: { params },
      user: { token },
      fetchReportConfig,
    } = this.props;
    dispatch(fetchReportCategories(token));
    dispatch(fetchRoles(token));
    dispatch(fetchUsers(token));
    if (params.reportId && params.reportId !== 'add') {
      fetchReportConfig(token, params.reportId);
      this.setState({ isEdit: true });
      this.breadcrumbsConfig[1].title = 'Edit Report';
    } else {
      this.breadcrumbsConfig[1].title = 'Add Report';
    }
  }

  componentDidUpdate(prevProps) {
    const { reportConfig, categories } = this.props;
    const { isSubmitted, isEdit, sql } = this.state;
    // added successfully
    if (reportConfig !== prevProps.reportConfig && isSubmitted) {
      this.setState({ isSubmissionSuccessful: true });
    }
    if (categories) {
      if (!isEmpty(reportConfig) && sql === '' && isEdit) {
        const propsCopy = cloneDeep(this.props);
        const formattedReportConfig = formatReportConfig(propsCopy);
        this.setState({ ...formattedReportConfig });
      }
    }
  }

  submitReport = () => {
    const {
      dispatch,
      match: { params },
      user: { token },
    } = this.props;
    const { hasRequiredFieldsMissing, payload } = prepareSelfServiceReportPayload(this.state, this.props);
    if (hasRequiredFieldsMissing.length) {
      this.setState({ missingFieldsWarning: hasRequiredFieldsMissing });
    } else {
      if (this.state.isEdit) {
        dispatch(updateReport(token, payload, params.reportId));
      } else {
        dispatch(addReport(token, payload));
      }
      this.setState({ missingFieldsWarning: null, isSubmitted: true });
    }
  };

  resetStateFields = () => {
    this.setState({
      isSubmitted: false,
      isSubmissionSuccessful: false,
      name: '',
      category: '',
      description: '',
      permittedRoles: [],
      permittedUsers: [],
      sql: '',
      params: [
        {
          name: '',
          param_type: [],
          default: '',
          options: '',
          param_query_options: '',
        },
      ],
    });
  };

  onFieldChange = (value, field) => {
    this.setState({
      [field]: value,
    });
  };

  onParamFieldChange = (value, index, field) => {
    const { params } = this.state;
    const reportParamsCopy = cloneDeep(params);
    reportParamsCopy[index][field] = value;
    this.setState({
      params: reportParamsCopy,
    });
  };

  getOptionsByName = (propsName, requiredValue, requiredLabel) => {
    return get(this.props, propsName)
      ? get(this.props, propsName).reduce((agg, group) => {
          agg.push({
            label: get(group, requiredLabel),
            value: get(group, requiredValue),
          });
          return agg;
        }, [])
      : [];
  };

  addParameters = () => {
    const reportParams = cloneDeep(this.state.params);
    this.setState({
      params: [
        ...reportParams,
        {
          name: '',
          param_type: [],
          default: '',
          options: '',
          param_query_options: '',
        },
      ],
    });
  };

  removeParameters = indx => {
    const { params } = this.state;
    const reportParams = cloneDeep(params);
    this.setState({
      params: reportParams.filter((_, index) => index !== indx),
    });
  };

  render() {
    const { params, isEdit, isSubmitted, isSubmissionSuccessful, missingFieldsWarning } = this.state;
    const sortedParams = params.sort((report1, report2) => report1.id - report2.id);
    const reportParams = cloneDeep(sortedParams);
    let submitMessage = isEdit ? 'Report successfully changed.' : 'Report successfully added.';
    if (missingFieldsWarning) {
      submitMessage = `${missingFieldsWarning.join(', ')} field(s) missing.`;
    }
    const categoryOptions = this.getOptionsByName('categories', 'id', 'name');

    return (
      <DashboardSectionTile title="New Report">
        <VStack align="stretch" gap="10px">
          <Text as="p" color="accent.one.default" fontSize="16px" fontWeight="bold">
            Report Info
          </Text>
          <Grid templateColumns="repeat(2, 1fr)" gap="30px">
            <CustomFormControl width="100%" label="Name">
              <Input
                name="name"
                height="48px"
                bgColor="white"
                value={get(this.state, 'name')}
                onChange={e => this.onFieldChange(get(e, 'target.value'), 'name')}
              />
            </CustomFormControl>

            <SelectField
              width="100%"
              label="Category"
              height="48px"
              isClearable
              isSearchable
              closeMenuOnSelect
              title="category"
              name="category"
              isMulti={false}
              placeholder="Report Categories"
              defaultValue={get(this.state, 'category')}
              value={categoryOptions?.find(category => category.value === get(this.state, 'category'))}
              onChange={e => this.onFieldChange(get(e, 'value'), 'category')}
              options={categoryOptions}
            />
          </Grid>

          <Grid templateColumns="repeat(1, 1fr)" gap="30px">
            <CustomFormControl width="100%" label="Description">
              <Textarea
                bgColor="white"
                rows={8}
                value={get(this.state, 'description')}
                onChange={e => this.onFieldChange(get(e, 'target.value'), 'description')}
              />
            </CustomFormControl>
          </Grid>

          <Grid templateColumns="repeat(2, 1fr)" gap="30px">
            <SelectField
              width="100%"
              label="Role Permissions"
              height="48px"
              isClearable
              isSearchable
              closeMenuOnSelect
              title="role"
              name="role"
              isMulti
              placeholder="Role"
              value={get(this.state, 'permittedRoles').map(id => {
                const role = get(this.props, 'roles').find(r => r.id === id);
                if (role) {
                  return {
                    value: role.id,
                    label: role.name,
                  };
                }
                return undefined;
              })}
              onChange={e => {
                const permittedRoles = e.map(r => r.value);
                this.onFieldChange(permittedRoles, 'permittedRoles');
              }}
              options={this.getOptionsByName('roles', 'id', 'name')}
            />

            <SelectField
              width="100%"
              label="User Permissions"
              height="48px"
              isClearable
              isSearchable
              closeMenuOnSelect
              isMulti
              title="user"
              name="user"
              placeholder="User Email"
              value={get(this.state, 'permittedUsers').map(id => {
                const user = get(this.props, 'users').find(r => r.id === id);
                if (user) {
                  return {
                    value: user.id,
                    label: user.email,
                  };
                }
                return undefined;
              })}
              onChange={e => {
                const permittedUsers = e.map(u => u.value);
                this.onFieldChange(permittedUsers, 'permittedUsers');
              }}
              options={this.getOptionsByName('users', 'id', 'email')}
            />
          </Grid>

          <Text as="p" color="accent.one.default" fontSize="16px" fontWeight="bold">
            Report Parameters
          </Text>
          <Grid templateColumns="repeat(4, 1fr)" gap="30px">
            <CustomFormControl width="100%" label="Name" />
            <CustomFormControl width="100%" label="Type" />
            <CustomFormControl
              width="100%"
              label={
                <>
                  Default
                  <Tooltip content="For multi-select, specify value separated by comma. E.g GRINDERID1,GRINDERID2">
                    <i className="fa fa-info-circle" />
                  </Tooltip>
                </>
              }
            />
            <CustomFormControl
              width="100%"
              label={
                <>
                  Options
                  <Tooltip content="For multi-select, specify value(label) separated by comma. E.g GRINDERID1(Grinder Name),GRINDERID2(Another Grinder)">
                    <i className="fa fa-info-circle" />
                  </Tooltip>
                </>
              }
            />
          </Grid>
          {reportParams &&
            reportParams.length > 0 &&
            reportParams.map((param, index = 1) => (
              <Grid templateColumns="repeat(4, 1fr)" gap="30px">
                <CustomFormControl
                  width="100%"
                  label={index + 1}
                  display="flex"
                  gap="10px"
                  alignItems="center"
                  flexDirection="row"
                >
                  <HStack gap="9px">
                    <Input
                      height="48px"
                      width="100%"
                      bgColor="white"
                      name="name"
                      value={get(param, 'name')}
                      onChange={e => this.onParamFieldChange(get(e, 'target.value'), index, 'name')}
                    />
                  </HStack>
                </CustomFormControl>

                <SelectField
                  width="100%"
                  height="46px"
                  isSearchable
                  closeMenuOnSelect
                  name="param_type"
                  isMulti={false}
                  value={{
                    value: get(param, 'param_type'),
                    label: get(param, 'param_type'),
                  }}
                  onChange={e => this.onParamFieldChange(get(e, 'value'), index, 'param_type')}
                  options={[
                    {
                      value: 'multi-select',
                      label: 'multi-select',
                    },

                    {
                      value: 'date',
                      label: 'date',
                    },

                    {
                      value: 'text',
                      label: 'text',
                    },
                  ]}
                />

                <CustomFormControl width="100%">
                  <Input
                    height="48px"
                    bgColor="white"
                    name="default"
                    value={get(param, 'default')}
                    onChange={e => this.onParamFieldChange(get(e, 'target.value'), index, 'default')}
                  />
                </CustomFormControl>
                {/* 
                        TODO uncomment when backend POST can accomodate changes
                        <td>
                          <SelectField
                            className="ssr-options-type__select"
                            isSearchable
                            closeMenuOnSelect
                            name="options_type"
                            style={{ width: '150px' }}
                            multi={false}
                            value={get(param, 'options_type')}
                            onChange={e => this.onParamFieldChange(get(e, 'value'), index, 'options_type')}
                            options={[
                              {
                                value: 'manual',
                                label: 'Manual',
                              },

                              {
                                value: 'query',
                                label: 'Query',
                              },
                            ]}
                          />
                        </td> */}
                <CustomFormControl width="100%">
                  <Box display="flex" width="100%" alignItems="center">
                    <Input
                      height="48px"
                      width="100%"
                      bgColor="white"
                      name="options"
                      value={get(param, 'options')}
                      disabled={get(this.state, 'param_query_options')}
                      onChange={e => this.onParamFieldChange(get(e, 'target.value'), index, 'options')}
                    />
                    <IconButton
                      bgColor="inherit"
                      onClick={() => this.removeParameters(index)} // remove from the list
                    >
                      <MdDeleteOutline color="#000" size="20px" />
                    </IconButton>
                  </Box>
                </CustomFormControl>
                {/* <CustomFormControl width="fit-content">
                  <Flex width="100%" height="100%" justifyContent="center" alignItems="center">
                    <IconButton
                      bgColor="inherit"
                      onClick={() => this.removeParameters(index)} // remove from the list
                    >
                      <i className="la la-trash-o " />
                    </IconButton>
                  </Flex>
                </CustomFormControl> */}
              </Grid>
            ))}
          <Flex width="100%" marginTop="28px !important">
            <Box marginLeft="auto">
              <Button variant="outline" color="secondary" onClick={() => this.addParameters()}>
                Add Parameters
              </Button>
            </Box>
          </Flex>
          <CustomFormControl width="100%" label="Report Query">
            <Textarea
              bgColor="white"
              rows={8}
              name="query"
              value={get(this.state, 'sql')}
              onChange={e => this.onFieldChange(get(e, 'target.value'), 'sql')}
            />
            {isSubmitted && isSubmissionSuccessful && <Alert status="info" width="240px" title={submitMessage} />}
            {missingFieldsWarning && <Alert status="error" width="240px" title={submitMessage} />}
          </CustomFormControl>
          <HStack marginTop="23px !important">
            <Button
              variant="ghost"
              fontWeight="medium"
              fontSize="16px"
              paddingX="0px"
              marginRight="auto"
              onClick={() => {
                this.props.navigate('/self-service-reports');
              }}
            >
              <MdArrowBack />
              Back
            </Button>
            <Group gap="8px">
              <Button
                variant="outline"
                colorScheme="gray"
                width="116px"
                height="40px"
                fontWeight="black"
                fontSize="16px"
                onClick={() => {
                  this.resetStateFields();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                colorScheme="actionPrimary"
                width="116px"
                height="40px"
                fontWeight="black"
                fontSize="16px"
                onClick={() => {
                  this.submitReport();
                }}
                disabled={isSubmitted && isSubmissionSuccessful}
              >
                Submit
              </Button>
            </Group>
          </HStack>
        </VStack>
      </DashboardSectionTile>
    );
  }
}

export default withRouter(SelfServiceReport);
