import React from 'react';
import { AlertTitle, Box, Flex } from '@chakra-ui/react';
import { Alert } from '../../ui/alert';

const NoData = () => {
  return (
    <Alert
      status="warning"
      bg="warningBg"
      borderColor="warningBorder"
      w="500px"
      style={{ textAlign: 'center', margin: ' 20vh auto', justifyContent: 'center' }}
    >
      <Flex direction="column">
        <AlertTitle pl="10px" color="warning">
          <Box
            className="fa fa-inbox"
            style={{ backgroundColor: 'transparent', fontSize: '30px', color: 'lightgrey' }}
          />
          <Box style={{ backgroundColor: 'transparent', fontSize: '15px', color: 'lightgrey' }}>No Data</Box>
        </AlertTitle>
      </Flex>
    </Alert>
  );
};

export default NoData;
