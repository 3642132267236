import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ColdstoreInventory from '../../containers/coldstore/ColdstoreInventory';
// import OrdersInColdstore from '../../containers/coldstore/OrdersInColdstore';
import Schedule from '../../containers/coldstore/Schedule';
import Release from '../../containers/coldstore/Release';
import Released from '../../containers/coldstore/Released';
import IUnleashClassFlagProvider from '../unleash/UnleashClassFlagProvider';
import Constants from '../../Constants';
import { fetchConfigs } from '../../actions/actions_config';
import { getInternalPoLabel } from '../../utils';
import { Tabs } from '@chakra-ui/react';
import ColdStoreManagementTabList from '../coldstore/coldstore-inventory/ColdStoreManagementTabList';
import HorizontalNavigationBand from '../core/HorizontalNavigationBand';

class ColdstoreManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: {
        type: 'coldstore_inventory',
        tab: 'coldstore_inventory',
        index: 0,
      },
      tabs: [
        {
          type: 'coldstore_inventory',
          name: 'coldstore_inventory',
          label: 'Coldstore Inventory',
        },
        {
          type: 'release_management',
          name: 'schedule',
          label: 'Schedule',
        },
        {
          type: 'release_management',
          name: 'release',
          label: 'Release',
        },
        {
          type: 'release_management',
          name: 'released',
          label: 'Released',
        },
      ],
    };
  }

  componentDidMount() {
    const { configs, dispatch, user } = this.props;
    if (configs?.length === 0) {
      dispatch(fetchConfigs(user?.token));
    }
  }

  handleNavigation = (type, tab) => {
    const { tabs } = this.state;
    const tabIndex = tabs.findIndex(tabItem => tabItem.name === tab);
    this.setState({
      active: { type, tab, index: tabIndex },
    });
  };

  render() {
    const { configs } = this.props;
    const internal_po_label = getInternalPoLabel(configs);
    const { active, tabs } = this.state;
    return (
      <div style={{ marginBottom: 0 }}>
        <div style={{ marginBottom: 'var(--chakra-space-9)', height: '100%', background: 'none', boxShadow: 'none' }}>
          <div style={{ height: 'auto', background: 'var(--chakra-colors-white)' }}>
            <div>
              <Tabs.Root defaultValue={0} value={active.index}>
                <HorizontalNavigationBand justifyContent="flex-start" paddingX="52px">
                  <ColdStoreManagementTabList
                    tabs={tabs}
                    handleNavigation={(type, tab) => this.handleNavigation(type, tab)}
                  />
                </HorizontalNavigationBand>
                <Tabs.Content value={0}>
                  {active.type === 'coldstore_inventory' && (
                    <ColdstoreInventory handleNavigation={this.handleNavigation} internalPoLabel={internal_po_label} />
                  )}
                </Tabs.Content>
                <Tabs.Content value={1}>
                  {active.type === 'release_management' && active.tab === 'schedule' && (
                    <Schedule handleNavigation={this.handleNavigation} internalPoLabel={internal_po_label} />
                  )}
                </Tabs.Content>
                <Tabs.Content value={2}>
                  {active.tab === 'release' && (
                    <Release handleNavigation={this.handleNavigation} internalPoLabel={internal_po_label} />
                  )}
                </Tabs.Content>
                <Tabs.Content value={3}>
                  {active.tab === 'released' && (
                    <Released handleNavigation={this.handleNavigation} internalPoLabel={internal_po_label} />
                  )}
                </Tabs.Content>
              </Tabs.Root>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
ColdstoreManagement.propTypes = {
  configs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.shape({
    token: PropTypes.string.isRequired,
  }).isRequired,
};

const ColdstoreManagementUnleashController = props => {
  return (
    <>
      <IUnleashClassFlagProvider
        flagName={Constants.UNLEASH_FLAG.COLD_STORE}
        show={<ColdstoreManagement {...props} />}
        hide={<h1>Feature Not Enabled</h1>}
      />
    </>
  );
};

export default ColdstoreManagementUnleashController;
