import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Text } from '@chakra-ui/react';
import {
  DialogRoot,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from '@/components/ui/dialog';

const DeleteDocumentDialog = ({ show, editItem, handleClose, handleDelete }) => {
  const [isLoading, setIsLoading] = useState(false);
  const deleteHandler = () => {
    setIsLoading(true);
    handleDelete(editItem.id);
    setIsLoading(false);
  };

  return (
    <DialogRoot open={show} onClose={handleClose} closeOnOverlayClick={false}>
      <DialogContent maxW="min(400px, 80%)">
        <DialogHeader closeButton><Text fontSize="20px" fontWeight="medium">Delete Document</Text></DialogHeader>
        <DialogCloseTrigger onClick={handleClose} />
        <DialogBody>
          <Text fontSize="15px" color="gray.500">Are you sure you want to delete this document? This action cannot be undone.</Text>
        </DialogBody>
        <DialogFooter>
          <Button variant="secondary" onClick={handleClose} loading={isLoading}>
            Cancel
          </Button>
          <Button backgroundColor="error.500" onClick={deleteHandler} loading={isLoading}>
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

export default DeleteDocumentDialog;

DeleteDocumentDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  editItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};
