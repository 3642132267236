import { cloneDeep, get, isEmpty, startCase } from 'lodash';
import mapboxgl from 'mapbox-gl';
import React, { Component } from 'react';
import { fetchAusCOVID19Data } from '../../../actions/actions_current_affairs';
import './Covid19Aus.scss';
import { Spinner } from '@chakra-ui/react';

export default class Covid19Aus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataTypes: [
        /* example keys of the array that goes here
         {
          key: null,
          color: null,
          heading: null
        } */
      ],
    };
  }

  componentDidMount() {
    mapboxgl.accessToken = 'pk.eyJ1IjoiY2Zjb2pvbm8iLCJhIjoiY2toeWNhOXh3MGY0cDJ4b2RhMG54M3ExaSJ9.hlvEPe6nwdJ1a3z1dGB5bA';
    if (isEmpty(this.props.aus_covid19_data)) {
      this.props.dispatch(fetchAusCOVID19Data(this.props.user.token));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.aus_covid19_data != this.props.aus_covid19_data) {
      const map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/dark-v9',
        // default centered to NSW
        center: [145, -31],
        zoom: 4.8,
      });

      map.once('style.load', ev => {
        const ausCovid19 = cloneDeep(this.props.aus_covid19_data);
        Object.keys(ausCovid19).forEach(key => {
          if (ausCovid19[key].visible == true) {
            const clusterKey = ausCovid19[key].textField;

            map.addSource(key, {
              type: 'geojson',
              data: ausCovid19[key].dataSource,
              cluster: ausCovid19[key].cluster,
              clusterMaxZoom: 14,
              clusterRadius: 30,
              // TODO FIXME hard coded
              clusterProperties: {
                confirmed: ['+', ['get', clusterKey]],
              },
            });
            const { layerInfo } = ausCovid19[key];
            layerInfo.map(info => {
              // paint will have properties like colour, radius
              if (info.layerType.type == 'paint') {
                // this is for clustering, not needed for packers and grinders or non clustered data
                if (ausCovid19[key].cluster == true) {
                  map.addLayer({
                    id: info.id,
                    type: info.type,
                    source: info.source,
                    filter: ['>', `${ausCovid19[key].textField}`, 0],
                    paint: {
                      'circle-color': info.layerType.color,
                      'circle-stroke-color': info.layerType.color,
                      'circle-opacity': 0.5,
                      // TODO FixME to use circle radius mathematical expression
                      'circle-radius': [
                        'step',
                        ['get', `${ausCovid19[key].textField}`],
                        0,
                        0,
                        5,
                        10,

                        8,
                        40,

                        10,
                        50,

                        14,
                        100,

                        18,
                        200,

                        20,
                        300,

                        25,
                        500,

                        30,
                        1000,

                        33,
                        2000,

                        35,
                        3000,

                        37,
                        5000,

                        39,
                        7000,

                        41,
                        9000,

                        43,
                      ],
                    },
                  });
                  // clustered mostly current issues, display properties in it on click
                  map.on('click', info.id, function (e) {
                    const data = [];
                    for (const [key, value] of Object.entries(e.features[0].properties)) {
                      // TODO FixME  avoid showing mapbox default cluster information better
                      if (!(key.match(/^cluster.*$/) || key.match(/^point_count.*$/))) {
                        data.push(`${startCase(key)}: ${parseInt(value)}`);
                      }
                    }
                    new mapboxgl.Popup().setLngLat(e.lngLat).setHTML(data).addTo(map);
                  });
                } else {
                  map.addLayer({
                    id: info.id,
                    type: info.type,
                    source: info.source,
                    paint: {
                      'circle-color': info.layerType.color,
                      'circle-stroke-color': info.layerType.color,
                      'circle-stroke-width': 1,
                      'circle-radius': 7, // info.layerType['radius']
                    },
                  });

                  // non-cluster: packer_plant and grinder
                  // display name and percentage by default [not on click]
                  // get features which has lat, lng to add msg boxes
                  // Dt: 08-05-2020 business wanted to change it
                  // if (key === 'packers' || key === 'grinders') {
                  //   const features = ausCovid19[key]['dataSource']['features'];
                  //   features.map(o => {
                  //     const volumes = o['properties']['volumes'] || '';
                  //     const data = `<span class="square ${key}"></span> <b>${o['properties']['name']}</b>  <h6>${volumes}</h6>`;
                  //     new mapboxgl.Popup({ closeOnClick: false })
                  //       .setLngLat(o['geometry']['coordinates'])
                  //       .setHTML(data)
                  //       .addTo(map);
                  //   });
                  // }
                  // when default msg is closed, re-click to see it
                  map.on('click', info.id, function (e) {
                    let volumes = {};
                    if (key == 'packers') {
                      volumes = JSON.parse(e.features[0].properties.volumes);
                    }
                    const turosi_percentages = get(volumes, 'turosi_bought_percentage', '');
                    const tyson_au_percentages = get(volumes, 'tyson_au_bought_percentage', '');
                    const anzco_percentages = get(volumes, 'anzco_bought_percentage', '');
                    const total_purchased_percentage = get(volumes, 'total_purchased_percentage', '');

                    const displayData = `
                      <div class="covid19-aus__pop-up">
                          <span class="square ${key}"></span> <b> ${e.features[0].properties.name} </b> </br>
                          ${
                            total_purchased_percentage &&
                            `<h6 style="color:orange"> ${total_purchased_percentage}% </h6> `
                          }
                          ${turosi_percentages && `<b>${turosi_percentages}%</b> - TurOsi <br/>`}
                          ${tyson_au_percentages && `<b>${tyson_au_percentages}%</b> - Tyson AU <br/>`}
                          ${anzco_percentages && `<b>${anzco_percentages}%</b> - ANZCO <br/>`}
                      </div> `;

                    new mapboxgl.Popup().setLngLat(e.lngLat).setHTML(displayData).addTo(map);
                  });
                }

                // populating state to use in info-box
                const dataTypes = cloneDeep(this.state.dataTypes);
                dataTypes.push({
                  key,
                  color: info.layerType.color || '',
                  heading: `${key.toUpperCase()} ${startCase(ausCovid19[key].textField)}`,
                });
                this.setState({ dataTypes });
              }
              // layout is used for displaying text fields
              // TODO FixME cluster sum not working
              else if (info.layerType.type === 'layout') {
                map.addLayer({
                  id: info.id,
                  type: info.type,
                  source: info.source,
                  filter: ['>', `${ausCovid19[key].textField}`, 0],
                  layout: {
                    'text-field': `{${ausCovid19[key].textField}}`,
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 12,
                    'text-allow-overlap': true,
                  },
                });
              }
            });
          }
        });
      });
      // Hide loading bar once tiles from geojson are loaded
      map.on('data', function (e) {
        if (e.dataType === 'source') {
          document.getElementById('loader').style.visibility = 'hidden';
        }
      });
    }
  }

  render() {
    return (
      <div className="covid19_aus">
        <div className="covid19-aus__info-box">
          {this.state.dataTypes &&
            this.state.dataTypes.map(topic => (
              <div key={topic.key}>
                <i className="fa fa-square" style={{ color: topic.color }} /> {topic.heading}
                <br />
              </div>
            ))}
          <div>
            <i className="fa fa-info-circle covid19-aus__info-box-ul-header" /> Information on click of packer_plant
            <ul className="covid19-aus__info-box-ul">
              <li className="covid19-aus__info-box-global-percentage">
                Percentage of total <span style={{ color: 'orange' }}>global</span> volume purchased by FMG Australia in
                2019.
              </li>
              <li>Percentage of grinder raw material supplied in 2019.</li>
            </ul>
          </div>
        </div>
        <div id="map" />
        <div
          className="loader loader--style1"
          title="0"
          id="loader"
          style={{ textAlign: 'center', margin: '15vh auto' }}
        >
          <Spinner size="xl" />
        </div>
      </div>
    );
  }
}
