import React from 'react';
import { Alert as ChakraAlert } from '@chakra-ui/react';
import { CloseButton } from './close-button';
import PropTypes from 'prop-types';

export const Alert = React.forwardRef(function Alert(props, ref) {
  const { title, children, icon, closable, onClose, startElement, endElement, ...rest } = props;
  return (
    <ChakraAlert.Root ref={ref} {...rest}>
      {startElement || <ChakraAlert.Indicator>{icon}</ChakraAlert.Indicator>}
      {children ? (
        <ChakraAlert.Content>
          <ChakraAlert.Title>{title}</ChakraAlert.Title>
          <ChakraAlert.Description>{children}</ChakraAlert.Description>
        </ChakraAlert.Content>
      ) : (
        <ChakraAlert.Title flex="1">{title}</ChakraAlert.Title>
      )}
      {endElement}
      {closable && (
        <CloseButton size="sm" pos="relative" top="-2" insetEnd="-2" alignSelf="flex-start" onClick={onClose} />
      )}
    </ChakraAlert.Root>
  );
});

Alert.displayName = 'Alert';

Alert.propTypes = {
  status: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
  variant: PropTypes.oneOf(['solid', 'subtle', 'left-accent', 'top-accent']),
  title: PropTypes.string,
  icon: PropTypes.node,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  startElement: PropTypes.node,
  endElement: PropTypes.node,
  children: PropTypes.node,
};

Alert.defaultProps = {
  status: 'info',
  variant: 'solid',
  title: 'Alert',
  icon: null,
  closable: false,
  onClose: () => {},
  startElement: null,
  endElement: null,
};
