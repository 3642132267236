import React, { useState } from 'react';
import { Box, Tabs } from '@chakra-ui/react';
import OrderManagementTabList from '../orders/OrderManagementTabList';
import PoChangeHistory from '../../containers/orders/ChangeHistory';
// import EditInternationalOrder from '../orders/order-form/edit-form/EditInternationalOrder';

const PurchaseOrderPageComponent = props => {
  const TabList = [
    {
      type: 'order',
      name: 'order',
      label: 'Order',
    },
    {
      type: 'history',
      name: 'history',
      label: 'History',
    },
  ];

  const token = props.user.token;
  const poId = props.match.params.poId;

  // useEffect(() => {
  //   props.fetchPurchaseOrder(token, poId);
  //   props.fetchPackageWeights();
  //   props.fetchTransporter(token);
  //   props.fetchEntitiesData();
  // }, []);

  const [ActiveType, SetActiveType] = useState(TabList[0].name);
  const [ActiveIndex, SetActiveIndex] = useState(0);

  const handleTabNavigation = (type, name) => {
    const tabIndex = TabList.findIndex(tabItem => tabItem.name === name);
    SetActiveType(type);
    SetActiveIndex(tabIndex);
  };

  return (
    <Box mb={0}>
      <Tabs.Root index={ActiveIndex}>
        <OrderManagementTabList tabs={TabList} handleNavigation={(type, name) => handleTabNavigation(type, name)} />
        <Box minH="xl" paddingX="52px">
          <Tabs.Content paddingX="0px" paddingY="26px">
            {/*<EditInternationalOrder 
                currentOrder={props.currentOrder}
                poID={poId}
                onClose={null}
                {...props}
  />*/}
          </Tabs.Content>
          <Tabs.Content>
            <PoChangeHistory Title="History" displayRows={8} {...props} />
          </Tabs.Content>
        </Box>
      </Tabs.Root>
    </Box>
  );
};

export default PurchaseOrderPageComponent;
