import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { createListCollection } from '@chakra-ui/react';
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from '@/components/ui/select';

const PackerSelectBox = ({ onChange, value, ...rest }) => {
  const packers = useSelector(state => state.food_safety_quality?.packers);

  const optionsCollection = createListCollection({
    items:
      packers?.map(packer => ({
        value: packer.id,
        label: packer.name,
      })) || [],
  });

  return (
    <SelectRoot collection={optionsCollection} width="270px" value={[value]} onValueChange={onChange} {...rest}>
      <SelectTrigger>
        <SelectValueText placeholder="Select"/>
      </SelectTrigger>
      <SelectContent zIndex="10000">
        {optionsCollection?.items?.map(opt => (
          <SelectItem item={opt} key={opt.value}>
            {opt.label}
          </SelectItem>
        ))}
      </SelectContent>
    </SelectRoot>
  );
};

PackerSelectBox.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default PackerSelectBox;
