import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

const withRouter = Children => {
  return props => {
    const match = { params: useParams() };
    const navigate = useNavigate();
    const location = useLocation();

    return <Children {...props} match={match} location={location} navigate={navigate} />;
  };
};

export default withRouter;
