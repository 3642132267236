import { Box, Button, Group, Flex, Input, Text, IconButton, createListCollection } from '@chakra-ui/react';
import { Formik, useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { IoInformationCircleOutline } from 'react-icons/io5';
import moment from 'moment';
import FileUploader from './FileUploader';
import { uploadFileToS3Request } from '../../../data-fixtures/basic';
import api from '../../../api';
import { actions } from '../../../reducers/reducer_food_safety_quality';
import Constants from '../../../Constants';
import DatePicker from '../../core/DatePicker';
import { Tooltip } from '@/components/ui/tooltip';
import PackerSelectBox from './PackerSelectBox';
import { getTenantFromDomain } from '../../../helpers/tenant';
import {
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from '@/components/ui/dialog';
import { Alert } from '../../ui/alert';
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from '@/components/ui/select';
import { toaster } from '@/components/ui/toaster';

const tenant = getTenantFromDomain();

const bBOptsCollection = createListCollection({
  items: [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
  ],
});

const resourceTitle = {
  beef_bundle: `${tenant.includes('cfc') ? 'Product Bundle' : 'Beef Bundle'}`,
  haccp: 'HACCP Letter',
  annual_audit_report: 'Annual Site Audit Report',
  audit_certificate: 'Audit Certificate',
  btt: `${tenant.includes('cfc') ? 'Technical Guide' : 'BTT Guide / FMG Docs'}`,
  spec: 'Raw Material Product Specification',
};

const AddDocument = ({ editItem, resourceType, isOpenAddPopup, setIsOpenAddPopup }) => {
  const { addNewResource, updateResource } = actions;
  const dispatch = useDispatch();
  // const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [establishments, setEstablishments] = useState([]);

  const estsOptionsCollection = createListCollection({
    items: establishments.map(establishment => ({
      label: establishment.name,
      value: establishment.id,
    })),
  });

  const validationSchema = Yup.object().shape({
    rs_title: Yup.string().max(100).required('Title is Required'),
    rs_doc_number: Yup.string().max(30).required('Document No. is Required'),
    rs_path: Yup.string().nullable().required('File is Required'),
    rs_issued_date: Yup.date().required('Issued Date is Required'),
    ...(resourceType === Constants.RESOURCE_DOCUMENT_TYPES.beef_bundle && {
      activate_beef_bundle: Yup.string().required('Activation is Required'),
    }),
    ...((resourceType === Constants.RESOURCE_DOCUMENT_TYPES.haccp ||
      resourceType === Constants.RESOURCE_DOCUMENT_TYPES.annual_audit_report ||
      resourceType === Constants.RESOURCE_DOCUMENT_TYPES.audit_certificate) && {
      rs_associated_id: Yup.string().required('Packer is Required'),
      packer_plant_id: Yup.string().required('Packer Plant is Required'),
      rs_expired_on: Yup.date()
        .required('Expiry Date is Required')
        .when('rs_issued_date', (isseDate, schema) =>
          schema.min(isseDate ? new Date(isseDate) : new Date(), 'Expiry Date should be greater then Issued Date')
        ),
    }),
  });

  const packerResourcesTypes = [
    Constants.RESOURCE_DOCUMENT_TYPES.haccp,
    Constants.RESOURCE_DOCUMENT_TYPES.annual_audit_report,
    Constants.RESOURCE_DOCUMENT_TYPES.audit_certificate,
    Constants.RESOURCE_DOCUMENT_TYPES.letter_of_guarantee,
    Constants.RESOURCE_DOCUMENT_TYPES.allergen_statements,
    Constants.RESOURCE_DOCUMENT_TYPES.spec,
    Constants.RESOURCE_DOCUMENT_TYPES.coa_example,
    Constants.RESOURCE_DOCUMENT_TYPES.other,
    Constants.RESOURCE_DOCUMENT_TYPES.animal_welfare_audit_report,
  ];
  const establishmentResourcesTypes = [
    Constants.RESOURCE_DOCUMENT_TYPES.haccp,
    Constants.RESOURCE_DOCUMENT_TYPES.annual_audit_report,
    Constants.RESOURCE_DOCUMENT_TYPES.audit_certificate,
    Constants.RESOURCE_DOCUMENT_TYPES.letter_of_guarantee,
    Constants.RESOURCE_DOCUMENT_TYPES.allergen_statements,
    Constants.RESOURCE_DOCUMENT_TYPES.coa_example,
    Constants.RESOURCE_DOCUMENT_TYPES.other,
    Constants.RESOURCE_DOCUMENT_TYPES.animal_welfare_audit_report,
  ];
  const issueDateAlignmentTypes = [
    Constants.RESOURCE_DOCUMENT_TYPES.btt,
    Constants.RESOURCE_DOCUMENT_TYPES.beef_bundle,
    Constants.RESOURCE_DOCUMENT_TYPES.letter_of_guarantee,
    Constants.RESOURCE_DOCUMENT_TYPES.letter_of_guarantee,
    Constants.RESOURCE_DOCUMENT_TYPES.allergen_statements,
    Constants.RESOURCE_DOCUMENT_TYPES.coa_example,
    Constants.RESOURCE_DOCUMENT_TYPES.animal_welfare_audit_report,
    Constants.RESOURCE_DOCUMENT_TYPES.other,
  ];

  const addDocumentHandler = async formValues => {
    try {
      setIsLoading(true);
      let resource;
      let newValues = JSON.parse(JSON.stringify(formValues));

      if (formValues.rs_path instanceof File) {
        const filePath = await uploadFileToS3Request({ file: formValues.rs_path });
        newValues = { ...formValues, rs_path: filePath };

        if (
          [
            Constants.RESOURCE_DOCUMENT_TYPES.letter_of_guarantee,
            Constants.RESOURCE_DOCUMENT_TYPES.allergen_statements,
          ].includes(resourceType)
        ) {
          newValues.file_upload = true;
        }
      }

      if (editItem) {
        delete newValues.id;
        resource = await api.patch(`/op-doc-resources/${formValues.id}`, newValues);
      } else {
        resource = await api.post('/op-docs-resources', newValues);
      }
      const data = await resource.json();
      if (editItem) {
        dispatch(updateResource({ ...data, type: resourceType }));
      } else {
        dispatch(addNewResource({ ...data, type: resourceType }));
      }
      setIsOpenAddPopup(false);
      toaster.create({
        title: `Document ${editItem ? 'Updated' : 'Added'} Successfully`,
        status: 'success',
      });
      setIsLoading(false);
    } catch (error) {
      toaster.create({
        title: error.message,
        status: 'error',
      });
      setIsLoading(false);
    }
  };

  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: {
      rs_title: '',
      rs_doc_number: '',
      rs_path: null,
      rs_issued_date: '',
      rs_type_name: resourceType,
      rs_associated_id: '',
      packer_plant_id: '',
      rs_expired_on: '',
      activate_beef_bundle: '',
    },
    validationSchema,
    onSubmit: addDocumentHandler,
  });

  const onPackerChange = async value => {
    setFieldValue('rs_associated_id', value);
    setIsLoading(true);
    const response = await api.get(`/packer/${value}/packer_plants`);
    const data = await response.json();
    setEstablishments(data);
    setIsLoading(false);
  };

  const onPackerPlantChange = e => {
    setFieldValue('packer_plant_id', e.value[0]);
  };

  useEffect(() => {
    if (editItem) {
      const { id, rs_title, rs_doc_number, rs_path, rs_issued_date } = editItem;
      setFieldValue('id', id);
      setFieldValue('rs_title', rs_title);
      setFieldValue('rs_doc_number', rs_doc_number);
      setFieldValue('rs_path', rs_path);
      setFieldValue('rs_issued_date', rs_issued_date);

      if (resourceType === Constants.RESOURCE_DOCUMENT_TYPES.beef_bundle) {
        setFieldValue('activate_beef_bundle', editItem.status?.toLowerCase() === 'active' ? 'yes' : 'no');
      }

      if (establishmentResourcesTypes.includes(resourceType)) {
        setFieldValue('rs_associated_id', editItem.rs_associated_id);
        if (editItem.rs_associated_id) {
          onPackerChange(editItem.rs_associated_id);
        }
        setFieldValue('rs_expired_on', editItem.rs_expired_on);
        setFieldValue('packer_plant_id', editItem.packer_plant_id);
      }
    }
  }, [editItem]);

  return (
    <DialogRoot open={isOpenAddPopup} onClose={() => setIsOpenAddPopup(false)} closeOnOverlayClick={false}>
      <DialogContent maxW="min(900px, 80%)">
        <DialogHeader borderBottomWidth="1px" fontSize="20px">
          {editItem ? 'Edit' : 'Add'} Document
        </DialogHeader>
        <DialogCloseTrigger asChild onClick={() => setIsOpenAddPopup(false)} />
        <Formik>
          <form onSubmit={handleSubmit}>
            <DialogBody padding="22px">
              <Flex gap="20px" css={{ gap: '20px' }} marginBottom="20px" flexWrap="wrap">
                {packerResourcesTypes.includes(resourceType) && (
                  <Box w="calc(50% - 20px)">
                    <Text as="p">
                      Packer&nbsp;
                      <Tooltip
                        content={`Choose the name of the packer associated with the ${resourceTitle[resourceType]}.`}
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                    </Text>

                    <PackerSelectBox
                      width="100%"
                      onChange={e => onPackerChange(e.value[0])}
                      value={values.rs_associated_id}
                    />
                    {errors.rs_associated_id && touched.rs_associated_id && (
                      <Alert status="error" title={errors.rs_associated_id} />
                    )}
                  </Box>
                )}
                {establishmentResourcesTypes.includes(resourceType) && (
                  <Box w="calc(50% - 20px)">
                    <Text as="p">
                      Packer Plant&nbsp;
                      <Tooltip
                        content={`Choose the name of the packer plant associated with the ${resourceTitle[resourceType]}.`}
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                    </Text>

                    <SelectRoot
                      collection={estsOptionsCollection}
                      value={[values.packer_plant_id]}
                      onValueChange={onPackerPlantChange}
                    >
                      <SelectTrigger>
                        <SelectValueText />
                      </SelectTrigger>
                      <SelectContent zIndex="10000">
                        {estsOptionsCollection.items.map(opt => (
                          <SelectItem item={opt} key={opt.value}>
                            {opt.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </SelectRoot>
                    {errors.packer_plant_id && touched.packer_plant_id && (
                      <Alert status="error" title={errors.packer_plant_id} />
                    )}
                  </Box>
                )}
                <Box w="calc(50% - 20px)">
                  Title&nbsp;
                  <Tooltip
                    content={`Enter the title of the ${resourceTitle[resourceType]}`}
                    positioning={{ placement: 'right-end' }}
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      variant="unstyled"
                    >
                      <IoInformationCircleOutline size="14px" />
                    </IconButton>
                  </Tooltip>
                  <Input name="rs_title" value={values.rs_title} onChange={handleChange} />
                  {errors.rs_title && touched.rs_title && <Alert status="error" title={errors.rs_title} />}
                </Box>
                <Box w="calc(50% - 20px)">
                  Document No.&nbsp;
                  <Tooltip
                    content={`Enter the document number of the ${resourceTitle[resourceType]}`}
                    positioning={{ placement: 'right-end' }}
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      variant="unstyled"
                    >
                      <IoInformationCircleOutline size="14px" />
                    </IconButton>
                  </Tooltip>
                  <Input name="rs_doc_number" value={values.rs_doc_number} onChange={handleChange} />
                  {errors.rs_doc_number && touched.rs_doc_number && (
                    <Alert status="error" title={errors.rs_doc_number} />
                  )}
                </Box>
                <Box w={`${issueDateAlignmentTypes.includes(resourceType) ? '100%' : 'calc(50% - 20px)'}`}>
                  Issued Date&nbsp;
                  <Tooltip
                    content={`Select the date when the ${resourceTitle[resourceType]} was issued.`}
                    positioning={{ placement: 'right-end' }}
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      variant="unstyled"
                    >
                      <IoInformationCircleOutline size="14px" />
                    </IconButton>
                  </Tooltip>
                  <DatePicker
                    id="rs_issued_date"
                    width="100%"
                    value={moment(values.rs_issued_date, Constants.DEFAULT_READ_DATE_FORMAT)}
                    onChange={e => {
                      setFieldValue('rs_issued_date', e);
                    }}
                  />
                  {errors.rs_issued_date && touched.rs_issued_date && (
                    <Alert status="error" title={errors.rs_issued_date} />
                  )}
                </Box>
                {(resourceType === Constants.RESOURCE_DOCUMENT_TYPES.haccp ||
                  resourceType === Constants.RESOURCE_DOCUMENT_TYPES.annual_audit_report ||
                  resourceType === Constants.RESOURCE_DOCUMENT_TYPES.audit_certificate) && (
                  <Box w="calc(50% - 20px)">
                    Expiry Date&nbsp;
                    <Tooltip
                      content={`Select the date when the ${resourceTitle[resourceType]} will expire.`}
                      positioning={{ placement: 'right-end' }}
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        variant="unstyled"
                      >
                        <IoInformationCircleOutline size="14px" />
                      </IconButton>
                    </Tooltip>
                    <DatePicker
                      id="rs_expired_on"
                      width="100%"
                      value={moment(values.rs_expired_on, Constants.DEFAULT_READ_DATE_FORMAT)}
                      onChange={e => {
                        setFieldValue('rs_expired_on', e);
                      }}
                    />
                    {errors.rs_expired_on && touched.rs_expired_on && (
                      <Alert status="error" title={errors.rs_expired_on} />
                    )}
                  </Box>
                )}
              </Flex>
              <Flex gap="20px" css={{ gap: '20px' }} marginBottom="20px">
                <Box flexGrow={1}>
                  <FileUploader
                    setFieldValue={setFieldValue}
                    fieldName="rs_path"
                    fileObject={values.rs_path}
                    accept=".pdf"
                  />
                  {errors.rs_path && touched.rs_path && <Alert status="error" title={errors.rs_path} />}
                </Box>
              </Flex>
              {resourceType === 'beef_bundle' && (
                <Box flexGrow={1}>
                  <Flex alignItems="center" marginBottom="5px">
                    <Text as="p" marginRight="10px">
                      Would you like to activate this {tenant.includes('cfc') ? 'Product Bundle' : 'Beef Bundle'}?
                    </Text>
                    <Tooltip
                      content={
                        tenant.includes('cfc')
                          ? "Only current year's Product Bundle is active."
                          : "Only current year's Beef Bundle is active."
                      }
                      positioning={{ placement: 'right-end' }}
                    >
                      <IconButton
                        width="14px"
                        height="14px"
                        padding="0"
                        minW="auto"
                        borderRadius="50%"
                        color="#878787"
                        variant="unstyled"
                      >
                        <IoInformationCircleOutline size="14px" />
                      </IconButton>
                    </Tooltip>
                  </Flex>

                  <SelectRoot
                    collection={bBOptsCollection}
                    value={[values.activate_beef_bundle]}
                    onValueChange={e => setFieldValue('activate_beef_bundle', e.value[0])}
                  >
                    <SelectTrigger>
                      <SelectValueText />
                    </SelectTrigger>
                    <SelectContent zIndex="10000">
                      {bBOptsCollection.items.map(opt => (
                        <SelectItem item={opt} key={opt.value}>
                          {opt.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </SelectRoot>
                  {errors.activate_beef_bundle && touched.activate_beef_bundle && (
                    <Alert status="error" title={errors.activate_beef_bundle} />
                  )}
                </Box>
              )}
            </DialogBody>
            <DialogFooter borderTopWidth="1px" marginTop="26px" padding="16px 22px">
              <Group marginLeft="auto">
                <Button type="button" width="126px" height="43px" backgroundColor="gray.200" color="#333" onClick={() => setIsOpenAddPopup(false)}>
                  <Text as="p" fontSize="16px" fontWeight="black">
                    Close
                  </Text>
                </Button>
                &nbsp;
                <Button
                  type="submit"
                  width="126px"
                  height="43px"
                  colorScheme="actionPrimary"
                  marginLeft="10px"
                  loadingText="Saving..."
                  loading={isLoading}
                >
                  <Text as="p" fontSize="16px" fontWeight="black">
                    Save
                  </Text>
                </Button>
              </Group>
            </DialogFooter>
          </form>
        </Formik>
      </DialogContent>
    </DialogRoot>
  );
};

AddDocument.propTypes = {
  editItem:
    PropTypes.shape({
      id: PropTypes.number,
      rs_title: PropTypes.string,
      rs_doc_number: PropTypes.string || PropTypes.number,
      rs_path: PropTypes.string,
      rs_issued_date: PropTypes.string,
    }) || PropTypes.null,
  resourceType: PropTypes.string.isRequired,
  isOpenAddPopup: PropTypes.bool.isRequired,
  setIsOpenAddPopup: PropTypes.func.isRequired,
};

export default AddDocument;
