import React, { useEffect, useState } from 'react';
import { Button, Group, Box, Text, Flex } from '@chakra-ui/react';
import MultiSelect from 'react-select';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import api from '../../../api';
import { actions } from '../../../reducers/reducer_establishment_details';
import {
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from '@/components/ui/dialog';
import { toaster } from '@/components/ui/toaster';

const { requestEstablishmentDetailsUpdate } = actions;

const TypeSelector = ({ types, establishmentId, listedStatus, status }) => {
  // const toast = useToast();
  const dispatch = useDispatch();
  const allTypes = useSelector(state => state.establishmentDetails?.establishmentOptions || []);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([]);

  const changeTypeHandler = async e => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await api.patch(`/op-establishment-detail/${establishmentId}`, {
        establishment: { est_supported_options: selectedTypes.map(value => value.value) },
      });
      if (response.status === 200) {
        const json = await response.json();
        setIsOpenPopup(false);
        dispatch(requestEstablishmentDetailsUpdate(json));
        toaster.create({
          title: 'Types Changed Successfully',
          status: 'success',
        });
      }
    } catch (error) {
      toaster.create({
        title: 'Error',
        description: error.message,
        status: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setSelectedTypes(types?.map(type => ({ value: type.id, label: type.est_opt_name })));
  }, []);

  return (
    <>
      <Flex alignItems="center" gap={3} css={{ gap: '10px' }}>
        <Flex alignItems="center" gap={3} css={{ gap: '10px' }} flexWrap="wrap">
          {selectedTypes.length > 0 &&
            allTypes?.map(type => (
              <Text
                key={type.id}
                color={types?.filter(typ => typ.id === type.id).length > 0 ? '#76AC6E' : 'rgba(0, 0, 0, 0.38)'}
                fontFamily="Roboto"
                fontSize="12px"
                fontWeight="500"
                borderRadius="4px"
                border={`1px solid ${
                  types?.filter(typ => typ.id === type.id).length > 0 ? '#76AC6E' : 'rgba(0, 0, 0, 0.38)'
                }`}
                padding="6px 10px"
                lineHeight="1.2"
                opacity={
                  types?.filter(typ => typ.id === type.id).length > 0 &&
                  (listedStatus === 'Delisted' || status === 'Rejected')
                    ? '0.5'
                    : '1'
                }
              >
                {type.est_opt_name}
              </Text>
            ))}
        </Flex>
        <Button
          variant="unstyled"
          height="auto"
          lineHeight="1"
          color="#3A44B0"
          fontSize="12px"
          fontWeight="500"
          flexShrink={0}
          onClick={() => setIsOpenPopup(true)}
          disabled={listedStatus === 'Delisted'}
        >
          {selectedTypes.length > 0 ? 'Edit' : 'Add'} Type
        </Button>
      </Flex>
      <DialogRoot open={isOpenPopup} onClose={() => setIsOpenPopup(false)} closeOnOverlayClick={false}>
        <DialogContent maxW="min(700px, 80%)">
          <DialogHeader borderBottomWidth="1px" fontSize="20px">
            {selectedTypes.length > 0 ? 'Edit' : 'Add'} Type
          </DialogHeader>
          <DialogCloseTrigger asChild onClick={() => setIsOpenPopup(false)} />
          <form onSubmit={changeTypeHandler}>
            <DialogBody padding="22px">
              <Box flexGrow={1}>
                <label>Type</label>
                <MultiSelect
                  isMulti
                  value={selectedTypes}
                  options={allTypes?.map(type => ({ value: type.id, label: type.est_opt_name }))}
                  name="types"
                  onChange={setSelectedTypes}
                  theme={theme => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: `var(--chakra-colors-secondary-300, ${theme.colors.primary})`,
                      primary25: `var(--chakra-colors-secondary-100, ${theme.colors.primary25})`,
                    },
                  })}
                  styles={{
                    placeholder: styles => ({
                      ...styles,
                      lineHeight: '20px',
                      height: '17px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }),
                    input: styles => ({
                      ...styles,
                      height: '30px',
                      lineHeight: '30px',
                      borderColor: '#ccc',
                      paddingLeft: 0,
                      paddingRight: 0,
                      paddingTop: 0,
                      paddingBottom: 0,
                      marginBottom: '3px',
                    }),
                    indicatorsContainer: styles => ({
                      ...styles,
                      height: '40px',
                    }),
                    indicatorSeparator: styles => {
                      return {
                        ...styles,
                        backgroundColor: 'transparent',
                        marginTop: '1px',
                        marginBottom: '1px',
                      };
                    },
                    valueContainer: styles => ({
                      ...styles,
                      lineHeight: '16px',
                      minHeight: '38px',
                      paddingRight: 0,
                    }),
                    dropdownIndicator: styles => ({
                      ...styles,
                      paddingLeft: '3px',
                      paddingRight: '3px',
                      paddingTop: 0,
                      paddingBottom: 0,
                    }),
                    clearIndicator: styles => ({
                      ...styles,
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      paddingLeft: '1px',
                      paddingRight: '1px',
                    }),
                    control: styles => ({
                      ...styles,
                      minHeight: '40px',
                      cursor: 'pointer',
                      boxShadow: 'none',
                      border: '1px solid #DCDFE3',
                      borderRadius: '4px',
                    }),
                    option: styles => ({
                      ...styles,
                      cursor: 'pointer',
                      color: 'inherit',
                    }),
                    menu: styles => ({
                      ...styles,
                    }),
                    singleValue: styles => ({
                      ...styles,
                      color: '#9FA6AD',
                      fontWeight: '500',
                    }),
                    multiValue: styles => {
                      return {
                        ...styles,
                        backgroundColor: '#fff',
                        border: '1px solid #3A44B0',
                        color: '#3A44B0',
                        padding: '0px 5px',
                        fontSize: '12px',
                        borderRadius: '6px',
                      };
                    },
                    multiValueLabel: styles => ({
                      ...styles,
                      color: '#3A44B0',
                    }),
                    multiValueRemove: styles => ({
                      ...styles,
                      color: '#3A44B0',
                      padding: 0,
                      ':hover': {
                        color: '#3A44B0',
                      },
                    }),
                  }}
                />
              </Box>
            </DialogBody>
            <DialogFooter borderTopWidth="1px" marginTop="26px" padding="16px 22px">
              <Group marginLeft="auto">
                <Button type="button" fontSize="16px" width="126px" height="43px" backgroundColor="gray.200" color="#333" onClick={() => setIsOpenPopup(false)}>
                  Close
                </Button>
                &nbsp;
                <Button
                  type="submit"
                  width="126px"
                  height="43px"
                  colorScheme="actionPrimary"
                  marginLeft="10px"
                  fontSize="16px"
                  loadingText="Saving..."
                  loading={isLoading}
                >
                  Save
                </Button>
              </Group>
            </DialogFooter>
          </form>
        </DialogContent>
      </DialogRoot>
    </>
  );
};

TypeSelector.propTypes = {
  listedStatus: PropTypes.string,
  status: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.shape({})),
  establishmentId: PropTypes.number.isRequired,
};

export default TypeSelector;
