import { LB_TO_KG, UOM } from '../modules/const';
import { calculate76LeanWeightedAveragePrice } from '../modules/data/beef-weighted-average';
import { isOrderAngus } from '../modules/order-utils';

function isNumberOrNull(value) {
  return !Number.isNaN(value) ? value : null;
}

function filterOrders(orders, isAngus = false) {
  return orders.filter(order => {
    if (isAngus) {
      return isOrderAngus;
    }
    return !isOrderAngus(order);
  });
}

export const MENU_ITEMS = {
  BEEF_10_1: {
    id: 'BEEF_10_1',
    label: '10:1',
    calculatePrice: (periodOrders, uom) => {
      const nonAngusOrders = filterOrders(periodOrders, false);
      const priceFor76Lean = calculate76LeanWeightedAveragePrice(nonAngusOrders, 'buy_quantity');
      const pattyRatio = uom === UOM.LBS ? 10 : 10 / LB_TO_KG;
      const price = isNumberOrNull(priceFor76Lean / pattyRatio);
      return price;
    },
  },
  BEEF_4_1: {
    id: 'BEEF_4_1',
    label: '4:1',
    calculatePrice: (periodOrders, uom) => {
      const nonAngusOrders = filterOrders(periodOrders, false);
      const priceFor76Lean = calculate76LeanWeightedAveragePrice(nonAngusOrders, 'buy_quantity');
      const pattyRatio = uom === UOM.LBS ? 4 : 4 / LB_TO_KG;
      return isNumberOrNull(priceFor76Lean / pattyRatio);
    },
  },
  ANGUS_4_1: {
    id: 'ANGUS_4_1',
    label: 'Angus',
    calculatePrice: (periodOrders, uom) => {
      const angusOrders = filterOrders(periodOrders, true);
      const priceFor76Lean = calculate76LeanWeightedAveragePrice(angusOrders, 'buy_quantity');
      const pattyRatio = uom === UOM.LBS ? 4 : 4 / LB_TO_KG;
      return isNumberOrNull(priceFor76Lean / pattyRatio);
    },
  },
};
