import React, { Component } from 'react';
import { deleteSupportEmail, patchSupportEmail, postSupportEmail } from '../../actions/actions_support';
import { MdOutlineCancel } from "react-icons/md";


class SupportContacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supportEmails: props.support_emails || [],
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.error && nextProps.support_emails.length < this.state.supportEmails.length) {
      return;
    }

    this.setState({ supportEmails: nextProps.support_emails });
  }

  onAddClick() {
    const copy = JSON.parse(JSON.stringify(this.state.supportEmails));
    copy.push({
      name: '',
      email: '',
      editMode: true,
    });
    this.setState({ supportEmails: copy });
  }

  onEditClick = index => {
    const copy = JSON.parse(JSON.stringify(this.state.supportEmails));
    copy[index].editMode = true;
    this.setState({ supportEmails: copy });
  };

  onValueChange(index, fieldName, value) {
    const copy = JSON.parse(JSON.stringify(this.state.supportEmails));
    copy[index][fieldName] = value;
    this.setState({ supportEmails: copy });
  }

  onSaveClick(index) {
    const copy = JSON.parse(JSON.stringify(this.state.supportEmails));
    delete copy[index].editMode;

    if (copy[index].id) {
      this.props.dispatch(patchSupportEmail(copy[index], this.props.token));
    } else {
      this.props.dispatch(postSupportEmail(copy[index], this.props.token));
    }
  }

  onDelete = index => {
    const copy = JSON.parse(JSON.stringify(this.state.supportEmails));

    if (!copy[index].id) {
      copy.splice(index, 1);
      this.setState({ supportEmails: copy });
      return;
    }

    const shouldDelete = window.confirm('Are you sure you want to delete contact email?');
    if (shouldDelete) {
      this.props.dispatch(deleteSupportEmail(copy[index], this.props.token));
    }
  };

  render() {
    const supportEmails = this.state.supportEmails || [];
    const styles = { paddingRight: '0px', paddingBottom: '8px' };
    return (
      <div>
        <div className="m-portlet__head">
          <div className="m-portlet__head-caption">
            <div className="m-portlet__head-title">
              <h3 className="m-portlet__head-text">Support Contacts</h3>
            </div>
          </div>
        </div>
        <div
          style={{
            paddingLeft: '20px',
            paddingTop: '20px',
            marginLeft: '20px',
          }}
        >
          {supportEmails.map((item, index) => (
            <div className="row" key={index}>
              <div className="col-md-5">
                <div className="row">
                  <disv className="col-sm-4" style={styles}>
                    <input
                      id="name"
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      disabled={!item.editMode}
                      value={item.name}
                      onChange={e => this.onValueChange(index, 'name', e.target.value)}
                    />
                  </disv>
                  <div className="col-sm-5" style={styles}>
                    <input
                      id="email"
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      disabled={!item.editMode}
                      value={item.email}
                      onChange={e => this.onValueChange(index, 'email', e.target.value)}
                    />
                  </div>
                  <div className="col-sm-2" style={styles}>
                    {item.editMode ? (
                      <button
                        className="btn btn-sm btn-info"
                        onClick={() => {
                          this.onSaveClick(index);
                        }}
                      >
                        <i className="flaticon-interface" />
                      </button>
                    ) : (
                      <button
                        className="btn btn-sm btn-info"
                        onClick={() => {
                          this.onEditClick(index);
                        }}
                      >
                        <i className="flaticon-edit-1" />
                      </button>
                    )}
                    &nbsp;
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => {
                        this.onDelete(index);
                      }}
                    >
                      <MdOutlineCancel />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="row">
            <button
              className="btn btn-sm btn-success"
              style={{ marginLeft: '20px' }}
              onClick={() => {
                this.onAddClick();
              }}
            >
              <i className="flaticon-add" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default SupportContacts;
