import './FoodSafetyTable.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tabs, Box, VStack } from '@chakra-ui/react';
import HorizontalNavigationBand from '../core/HorizontalNavigationBand';
import UploadDocsButton from './_components/UploadDocsButton';
import FoodSafetyTabs from './_components/FoodSafetyTabs';
import FmgDocs from './resources/FmgDocs';
import Specifications from './resources/Specifications';
import BeefBundle from './resources/BeefBundle';
import Establishments from './establishments';
import HACCPLetters from './audit/HACCPLetters';
import AnnualSiteAuditReport from './audit/AnnualSiteAuditReport';
import AuditCertificate from './audit/AuditCertificate';
import ImportantContacts from './importantContacts';
import { fetchPackers } from '../../reducers/reducer_food_safety_quality';
import LetterofGuarantee from './LetterofGuarantee';
import AllergenStatements from './AllergenStatements';
import Constants from '../../Constants';
import CoaExample from './CoaExample';
import AnimalWelfare from './AnimalWelfare';
import OtherFacilityDocuments from './OtherFacilityDocuments';

/**
 * FoodSafetyQuality component.
 *
 * @returns {JSX.Element} The rendered FoodSafetyQuality component.
 */
const FoodSafetyQuality = () => {
  const dispatch = useDispatch();
  const [resourcesTab, setResourcesTab] = useState(Constants.RESOURCE_DOCUMENT_TYPES.btt);
  const [tabIndex, setTabIndex] = useState('resources');
  const [isOpenAddPopup, setIsOpenAddPopup] = useState(false);

  const handleTabsChange = e => {
    setTabIndex(e.value);
  };

  useEffect(() => {
    dispatch(fetchPackers());
  }, []);

  return (
    <Tabs.Root defaultValue="resources" onValueChange={handleTabsChange}>
      <HorizontalNavigationBand justifyContent="flex-start" paddingX="47px">
        <FoodSafetyTabs resourcesTab={resourcesTab} setResourcesTab={setResourcesTab} />
        <Box ml="auto">
          {tabIndex !== 'Establishments' && (
            <UploadDocsButton
              onClick={e => {
                e.preventDefault();
                setIsOpenAddPopup(true);
              }}
            />
          )}
        </Box>
      </HorizontalNavigationBand>

      <VStack background="white" align="stretch" spacing="28px" paddingX="61px">
        {/* Resources Tab Panel */}
        <Tabs.Content value="resources">
          {resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.btt && (
            <FmgDocs isOpenAddPopup={isOpenAddPopup} setIsOpenAddPopup={setIsOpenAddPopup} />
          )}
          {resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.beef_bundle && (
            <BeefBundle isOpenAddPopup={isOpenAddPopup} setIsOpenAddPopup={setIsOpenAddPopup} />
          )}
        </Tabs.Content>

        <Tabs.Content value="Establishments">{resourcesTab === 'Establishments' && <Establishments />}</Tabs.Content>
        <Tabs.Content value="HACCPLetters">
          {resourcesTab === 'HACCPLetters' && (
            <HACCPLetters isOpenAddPopup={isOpenAddPopup} setIsOpenAddPopup={setIsOpenAddPopup} />
          )}
        </Tabs.Content>
        <Tabs.Content value="LetterofGuarantee">
          {resourcesTab === 'LetterofGuarantee' && (
            <LetterofGuarantee isOpenAddPopup={isOpenAddPopup} setIsOpenAddPopup={setIsOpenAddPopup} />
          )}
        </Tabs.Content>
        <Tabs.Content value="AllergenStatements">
          {resourcesTab === 'AllergenStatements' && (
            <AllergenStatements isOpenAddPopup={isOpenAddPopup} setIsOpenAddPopup={setIsOpenAddPopup} />
          )}
        </Tabs.Content>
        <Tabs.Content value="ImportantContacts">
          {resourcesTab === 'ImportantContacts' && (
            <ImportantContacts isOpenAddPopup={isOpenAddPopup} setIsOpenAddPopup={setIsOpenAddPopup} />
          )}
        </Tabs.Content>

        <Tabs.Content value="more">
          {resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.audit_certificate && (
            <AuditCertificate isOpenAddPopup={isOpenAddPopup} setIsOpenAddPopup={setIsOpenAddPopup} />
          )}
          {resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.annual_audit_report && (
            <AnnualSiteAuditReport isOpenAddPopup={isOpenAddPopup} setIsOpenAddPopup={setIsOpenAddPopup} />
          )}
          {resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.spec && (
            <Specifications isOpenAddPopup={isOpenAddPopup} setIsOpenAddPopup={setIsOpenAddPopup} />
          )}
          {resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.coa_example && (
            <CoaExample isOpenAddPopup={isOpenAddPopup} setIsOpenAddPopup={setIsOpenAddPopup} />
          )}
          {resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.animal_welfare_audit_report && (
            <AnimalWelfare isOpenAddPopup={isOpenAddPopup} setIsOpenAddPopup={setIsOpenAddPopup} />
          )}
          {resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.other && (
            <OtherFacilityDocuments isOpenAddPopup={isOpenAddPopup} setIsOpenAddPopup={setIsOpenAddPopup} />
          )}
        </Tabs.Content>
      </VStack>
    </Tabs.Root>
  );
};

export default FoodSafetyQuality;
