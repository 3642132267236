import React, { useState, useEffect } from 'react';
import { Button, Flex, IconButton, Skeleton, Text } from '@chakra-ui/react';
import { FiArrowLeft } from 'react-icons/fi';
import { PropTypes } from 'prop-types';
import { IoEyeSharp } from 'react-icons/io5';
import { Tooltip } from '@/components/ui/tooltip';
import { previewFromS3Link } from '../../../helpers/downloads';
import { downloadFile } from '../../../utils';
import { DialogBody, DialogContent, DialogHeader, DialogRoot, DialogCloseTrigger } from '@/components/ui/dialog';
import { toaster } from '@/components/ui/toaster';

const ViewDocument = ({ url }) => {
  // const toast = useToast();
  const [openViewModal, setOpenViewModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [previewURL, setPreviewURL] = useState('');
  const [fileName, setFileName] = useState('...');

  const getPreviewUrl = async () => {
    try {
      setIsLoading(true);
      const fName = url?.split('/').pop();
      setFileName(fName);
      const signedUrl = await previewFromS3Link(url);
      setPreviewURL(signedUrl);
      setIsLoading(false);
    } catch (error) {
      toaster.create({
        title: error.message,
        status: 'error',
      });
      setIsLoading(false);
    }
  };

  const downloadFileHandler = async docUrl => {
    setDownloadLoading(true);
    const fName = docUrl?.split('/').pop();
    const signedUrl = await previewFromS3Link(docUrl);
    await downloadFile(signedUrl, fName);
    setDownloadLoading(false);
  };

  useEffect(() => {
    if (openViewModal) {
      getPreviewUrl();
    }
  }, [openViewModal]);

  return (
    <>
      <Tooltip content="View">
        <IconButton
          width="24px"
          height="24px"
          padding="0"
          minW="auto"
          border="1px solid #D9DADF"
          borderRadius="2px"
          color="#00000099"
          backgroundColor="#fff"
          onClick={() => setOpenViewModal(true)}
        >
          <IoEyeSharp size={12} />
        </IconButton>
      </Tooltip>
      {openViewModal && (
        <DialogRoot open={openViewModal} onClose={() => setOpenViewModal(false)} closeOnOverlayClick={false}>
          <DialogContent minW="90%" minH="calc(100vh - 100px)">
            <DialogHeader borderBottomWidth="1px" fontSize="20px">
              <Flex gap={3} css={{ gap: '10px' }} align="center" justifyContent="space-between">
                <Flex align="center">
                  <IconButton
                    marginRight="10px"
                    background="transparent"
                    _hover={{ background: 'transparent' }}
                    onClick={() => setOpenViewModal(false)}
                  >
                    <FiArrowLeft size="20px" color='#000'/>
                  </IconButton>
                  <Text>{decodeURI(fileName)}</Text>
                </Flex>
                <Flex justifyContent="center" align="center" gap={3} css={{ gap: '10px' }}>
                  <Button
                    background="#031B4E"
                    _hover={{ background: '#031B4E' }}
                    _focus={{ background: '#031B4E' }}
                    color="#fff"
                    onClick={() => downloadFileHandler(url)}
                    loadingText="Downloading..."
                    loading={downloadLoading}
                  >
                    Download
                  </Button>
                  <DialogCloseTrigger asChild onClick={() => setOpenViewModal(false)} pos="static" />
                </Flex>
              </Flex>
            </DialogHeader>
            <DialogBody padding="22px">
              {isLoading ? (
                <Flex justifyContent="center" alignItems="center" padding="20px" height="calc(100vh - 200px)">
                  <Skeleton height="100%" width="100%" />
                </Flex>
              ) : (
                <iframe
                  title="pdf-viewer"
                  src={`${previewURL}#toolbar=0&scrollbar=0&navpanes=0`}
                  width="100%"
                  style={{ height: 'calc(100vh - 200px)' }}
                />
              )}
            </DialogBody>
          </DialogContent>
        </DialogRoot>
      )}
    </>
  );
};

ViewDocument.propTypes = {
  url: PropTypes.string.isRequired,
};

export default ViewDocument;
