import React, { useState, useEffect } from 'react';
import { Upload } from 'antd';
import { Box, Flex, IconButton, Skeleton, Text } from '@chakra-ui/react';
import { MdOutlineCloudUpload, MdOutlineInsertDriveFile } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx';
import PropTypes from 'prop-types';
import formatBytes from '../../../helpers/formatBytes';
import { previewFromS3Link } from '../../../helpers/downloads';
import { toaster } from '@/components/ui/toaster';

const { Dragger } = Upload;

const FileUploader = (
  { setFieldValue, fieldName, label, fileObject, accept } = { label: 'Upload File', accept: 'application/pdf, .pdf' }
) => {
  // const toast = useToast();
  const [fileUrl, setFileUrl] = useState('');
  const [customFile, setCustomFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onChangeHandler = info => {
    const { status } = info.file;
    if (status === 'done') {
      toaster.create({
        title: 'Success',
        description: `${info.file.name} file uploaded successfully.`,
        status: 'success',
      });
    } else if (status === 'error') {
      toaster.create({
        title: 'Error',
        description: `${info.file.name} file upload failed.`,
        status: 'error',
      })
    }
  };

  const beforeUpload = file => {
    // check the file type - you can specify the types you'd like here:
    // const isValid = accept.includes(file.type);
    // if (!isValid) {
    //   toast({
    //     title: 'Error',
    //     description: `You can only upload ${accept} file!`,
    //     status: 'error',
    //     duration: 3000,
    //     isClosable: true,
    //   });
    //   return false;
    // }

    // check the file size - you can specify the file size you'd like here:
    const isLt20M = file.size / 1024 / 1024 <= 20;
    if (!isLt20M) {
      // toast({
      //   title: 'Error',
      //   description: 'You can only upload less then 20MB file!',
      //   status: 'error',
      //   duration: 3000,
      //   isClosable: true,
      // });
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (typeof fileObject === 'string' && fileObject !== '') {
      setIsLoading(true);
      const fileName = fileObject.substring(fileObject.lastIndexOf('/') + 1);
      previewFromS3Link(fileObject).then(url => {
        setFileUrl(url);
        fetch(url)
          .then(response => response.blob())
          .then(blob => {
            const fl = new File([blob], fileName || 'filename', { type: fileObject.type });
            setCustomFile(fl);
          })
          .catch(error => {
            toaster.create({
              title: 'Error',
              description: error.message,
              status: 'error',
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }
  }, []);

  return (
    <Box className="file-uploader">
      <label style={{ fontWeight: '500', fontSize: '14px', marginBottom: '5px', display: 'inline-block' }}>
        {label}
      </label>
      {isLoading ? (
        <Skeleton width="100%" height="60px" />
      ) : (
        <>
          {(fileObject && fileObject?.name) || customFile ? (
            <Flex
              justifyContent="space-between"
              alignItems="center"
              padding="15px"
              border="1px solid #EFEFEF"
              borderRadius="5px"
              fontSize="12px"
              color="#00000099"
              marginTop="20px"
              position="relative"
            >
              <Flex gap={3} css={{ gap: '10px' }} alignItems="center">
                <MdOutlineInsertDriveFile size="26px" />
                {fileUrl ? (
                  <a href={fileUrl} target="_blank" rel="noreferrer">
                    {decodeURI(fileObject?.name || customFile?.name)}
                  </a>
                ) : (
                  <Text>{decodeURI(fileObject?.name || customFile?.name)}</Text>
                )}
              </Flex>
              <Text>{formatBytes(fileObject?.size || customFile?.size)}</Text>
              <IconButton
                background="#FD5C38"
                padding="0"
                height="24px"
                minW="24px"
                w="24px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                position="absolute"
                top="-10px"
                right="-10px"
                _hover={{ background: '#FD5C38' }}
                _focus={{ background: '#FD5C38' }}
                _active={{ background: '#FD5C38' }}
                onClick={() => {
                  setFieldValue(fieldName, null);
                  setCustomFile(null);
                }}
              >
                <RxCross2 size="12px" color="#fff" />
              </IconButton>
            </Flex>
          ) : (
            <Dragger
              accept={accept}
              padding="0"
              name="file"
              multiple={false}
              customRequest={({ file }) => {
                setFieldValue(fieldName, file);
              }}
              onChange={onChangeHandler}
              showUploadList={false}
              beforeUpload={beforeUpload}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                borderRadius="4px"
                border="1px dashed #C9CFD7"
                padding="36px"
                textAlign="center"
                cursor="pointer"
              >
                <Flex flexDir="column" align="center" fontSize="14px" color="#00000061" userSelect="none">
                  <MdOutlineCloudUpload size="30px" />
                  <Text marginTop="10px">
                    <Text as="span" color="#3A44B0">
                      Click here to upload
                    </Text>
                    &nbsp;or drag & drop
                  </Text>
                  <Text>
                    Supported file types are&nbsp;
                    {accept.includes('application/pdf,') ? accept.replace('application/pdf,', 'PDF') : accept} Max file
                    size supported is 20 MB
                  </Text>
                </Flex>
              </Flex>
            </Dragger>
          )}
        </>
      )}
    </Box>
  );
};

FileUploader.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  fileObject: PropTypes.object,
  accept: PropTypes.string,
};

export default FileUploader;
