import cloneDeep from 'lodash/cloneDeep';
import groupBy from 'lodash/groupBy';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { Badge, Box, Flex, HStack, Image, Tabs, Text, VStack, Button } from '@chakra-ui/react';
import { fetchReportsList } from '../../../actions/actions_self_service_reports';
import SelectField from '../../basic/SelectField';
import { prepareQueryStringFromReportsList } from '../helper';
import AuthGate from '../../../containers/auth/AuthGate';
import './reportsList.scss';
import IUnleashClassFlagProvider from '../../unleash/UnleashClassFlagProvider';
import Constants from '../../../Constants';
import HorizontalNavigationBand from '../../core/HorizontalNavigationBand';
import DashboardSectionTile from '../../core/DashboardSectionTile';
import { Alert } from '../../ui/alert';
import withRouter from '@/components/utilities/withRouter';

// const svgDir = require.context('!@svgr/webpack!../../../img/self_service_reports_images');

class ReportsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMsg: null,
      activeTabIndex: 0,
      activeTab: null,
    };
  }

  componentDidMount() {
    const { dispatch, user } = this.props;
    dispatch(fetchReportsList(user.token));
  }

  componentDidUpdate(prevProps) {
    const { selfServiceReports } = this.props;
    if (selfServiceReports !== prevProps.selfServiceReports) {
      const reportsList = cloneDeep(selfServiceReports);
      const categories = [];
      const reportsInCategories = {};
      const reportsGroupedByCategory = groupBy(reportsList, 'category');

      const tabList = Object.keys(reportsGroupedByCategory).reduce((agg, category) => {
        const src = `img/self_service_reports_images/${category.toLowerCase()}.svg`;
        // populate categories to have easy access
        categories.push(category);
        agg.push({
          key: category,
          tab: (
            <div className="ssr-list__card-categories">
              <div className="ssr-list__card-categories-img">
                <img src={src} alt="self service report category" />
              </div>
              {category}
            </div>
          ),
          imageSrc: `${category.toLowerCase()}.svg`,
          imageAlt: 'self service report category',
          // Icon: svgDir(`./${category.toLowerCase()}-small.svg`).default,
          Icon: () => (
            <Image
              src={`../../../img/self_service_reports_images/${category.toLowerCase()}-small.svg`}
              alt="self service report category"
            />
          ),
        });
        return agg;
      }, []);

      const contentList = categories.reduce((agg, category) => {
        // Sort by report name.
        const reports = reportsGroupedByCategory[category].sort((a, b) => a.name.localeCompare(b.name));
        // make an array of category name and reports list
        // for easier search
        reportsInCategories[category] = reports.reduce((aggr, report) => {
          aggr.push(report.name);
          return aggr;
        }, []);
        return { ...agg, [category]: reports };
      }, []);

      const contentListCopy = cloneDeep(contentList);
      const categoryContentAsList = Object.values(contentListCopy)
        .reduce((agg, category) => {
          agg.push(category);
          return agg;
        }, [])
        .flat();

      this.setState({
        reportsInCategories,
        tabList,
        contentList,
        categoryContentAsList,
        activeTab: tabList[0]?.key,
      });
    }
  }

  onTabChange = tabName => {
    this.setState({ activeTab: tabName });
  };

  onSearchForReport = reportName => {
    const { navigate } = this.props;
    const availableCategories = cloneDeep(this.state.categoryContentAsList);
    const report = availableCategories.find(c => {
      return c.name === reportName;
    });

    if (report) {
      const queryParameters = prepareQueryStringFromReportsList(report.params);
      navigate(`/self-service-reports/${report.id}?${queryParameters}`);
    }
  };

  handleTabNavigation = index => {
    const { tabList } = this.state;
    const key = tabList[index]?.key;
    this.setState({
      // active_type: type,
      activeTab: key,
      activeTabIndex: index,
    });
  };

  render() {
    const { activeTab, reportsInCategories, errorMsg, tabList, contentList, activeTabIndex } = this.state;
    let reportsList = [];
    if (reportsInCategories) {
      reportsList = Object.values(reportsInCategories)
        .reduce((agg, category) => {
          agg.push(category);
          return agg;
        }, [])
        .flat();
    }

    return (
      <>
        <VStack gap="56px" align="stretch" marginBottom="95px">
          <HorizontalNavigationBand justifyContent="flex-start" paddingX="52px">
            <Flex gap="10px" width="420px" alignItems="center">
              <Text as="p" fontSize="14px" fontWeight="bold" whiteSpace="nowrap">
                Search for Reports:
              </Text>
              <SelectField
                options={reportsList.reduce((agg, report) => {
                  agg.push({
                    value: report,
                    label: report,
                  });
                  return agg;
                }, [])}
                key="ssr-list__search-input"
                {...{
                  label: '',
                  placeholder: 'global search & choose',
                  name: 'ssr-list__search-input',
                  isMulti: false,
                  closeMenuOnSelect: true,
                }}
                onChange={e => this.onSearchForReport(e.value)}
                optionStyles={{
                  fontWeight: 500,
                  fontSize: '13px',
                }}
              />
              {errorMsg !== null && <Alert status="error" title={errorMsg} />}
            </Flex>
            <Box ml="auto">
              <AuthGate requiredPermissions={['write_self_service_report']}>
                <Link to="/self-service-report/add">
                  <Button colorScheme="actionSecondary" height="43px" width="174px">
                    Add New Report
                  </Button>
                </Link>
              </AuthGate>
            </Box>
          </HorizontalNavigationBand>
          <VStack align="stretch" paddingX="51px">
            <DashboardSectionTile title="Reports (Self Service)">
              <Tabs.Root defaultValue={0} value={activeTabIndex} onValueChange={e => this.handleTabNavigation(e.value)}>
                <Tabs.List paddingBottom="30px" borderBottomWidth="1px" marginBottom="50px">
                  {tabList?.map((tab, index) => (
                    <Tabs.Trigger
                      value={index}
                      key={tab.key}
                      _focus={{ outline: 'none' }}
                      borderBottomWidth="0px"
                      height="auto"
                      className="tab-btn"
                      _before={{ display: 'none' }}
                    >
                      <Badge
                        variant="outline"
                        width="255px"
                        height="88px"
                        borderRadius="8px"
                        colorScheme={index === activeTabIndex ? 'accent.one' : 'actionPrimary'}
                        className="reporting-tab"
                      >
                        <HStack gap="25px" height="100%" justifyContent="center" alignItems="center">
                          {tab.Icon && <tab.Icon />}
                          <Text as="p" fontWeight="bold">
                            {tab.key}
                          </Text>
                        </HStack>
                      </Badge>
                    </Tabs.Trigger>
                  ))}
                </Tabs.List>
                {contentList &&
                  contentList[activeTab] &&
                  contentList[activeTab].map(report => {
                    const queryParameters = prepareQueryStringFromReportsList(report.params);

                    return (
                      <HStack
                        gap="35px"
                        boxShadow="md"
                        bgColor="white"
                        padding="8px 21px 8px 14px"
                        borderRadius="6px"
                        marginY="30px"
                      >
                        <VStack align="stretch">
                          <Flex
                            justifyContent="center"
                            alignItems="center"
                            bgColor="gray.200"
                            width="40px"
                            height="40px"
                            borderRadius="20px"
                          >
                            <Image
                              src={`../../../img/self_service_reports_images/${activeTab.toLowerCase()}-small.svg`}
                              width="20px"
                              height="20px"
                            />
                          </Flex>
                        </VStack>
                        <Flex width="100%" alignItems="center">
                          <VStack>
                            <Box mr="10px">
                              <Text as="p" fontSize="16px" fontWeight="semibold">
                                {report.name}
                              </Text>
                              <Text as="p">{report.description}</Text>
                            </Box>
                          </VStack>
                          <Box ml="auto">
                            <Link to={`/self-service-reports/${report.id}?${queryParameters}`}>
                              <Button colorScheme="actionPrimary" height="46px" width="97px">
                                <Text as="p" fontSize="16px" fontWeight="normal">
                                  View
                                </Text>
                              </Button>
                            </Link>
                          </Box>
                        </Flex>
                      </HStack>
                    );
                  })}
              </Tabs.Root>
            </DashboardSectionTile>
          </VStack>
        </VStack>
      </>
    );
  }
}

const ReportListFeatureUnleashController = props => {
  return (
    <>
      <IUnleashClassFlagProvider
        flagName={Constants.UNLEASH_FLAG.SELF_SERVICE_REPORT}
        show={<ReportsList {...props} />}
        hide={<h1>Feature Not Enabled</h1>}
      />
    </>
  );
};

ReportsList.propTypes = {
  dispatch: PropTypes.func,
  user: PropTypes.shape({ token: PropTypes.string }),
  selfServiceReports: PropTypes.shape({
    category: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    params: PropTypes.arrayOf(
      PropTypes.shape({
        default: PropTypes.string,
        id: PropTypes.number,
        label: PropTypes.string,
        name: PropTypes.string,
        options: PropTypes.string,
        param_type: PropTypes.string,
        report_id: PropTypes.number,
      })
    ),
    permissions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        report_id: PropTypes.number,
        role_id: PropTypes.number,
        user_id: PropTypes.number,
      })
    ),
    sql: PropTypes.string,
  }),
  navigate: PropTypes.func,
};

export default withRouter(ReportListFeatureUnleashController);
