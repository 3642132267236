import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, Group, Input, Flex } from '@chakra-ui/react';
import { Formik, useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import api from '../../../api';
import {
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from '@/components/ui/dialog';
import { Field } from '@/components/ui/field';
import { toaster } from '@/components/ui/toaster';

const UpdateContactInfo = ({ isOpenPopup, setIsOpenPopup, estId, editItem, updateList }) => {
  const [isLoading, setIsLoading] = useState(false);

  const updateContactInfo = async values => {
    const payload = {
      screen: 'contactinfo',
      fields_info: values,
    };

    try {
      setIsLoading(true);
      if (estId) {
        const response = await api.patch(`/op-establishment/${estId}`, payload);
        if (response.ok) {
          const dt = await response.json();
          updateList(allList => {
            const updatedList = allList.map(item => {
              if (item.id === estId) {
                return { ...item, contacts_info: dt };
              }
              return item;
            });
            return updatedList;
          });
          toaster.create({
            title: 'Record updated successfully',
            status: 'success',
          });
        }
      }
    } catch (error) {
      toaster.create({
        title: error.message,
        status: 'error',
      });
    } finally {
      setIsLoading(false);
      setIsOpenPopup(false);
    }
  };

  const InitialValues = [
    {
      id: 0,
      info_type: 'Business Owner',
      fullname: '',
      mobile: '',
      email: '',
      after_hours: '',
      position: '',
    },
    {
      id: 0,
      info_type: 'Quality Contact',
      fullname: '',
      mobile: '',
      email: '',
      after_hours: '',
      position: '',
    },
    {
      id: 0,
      info_type: 'Sales Manager',
      fullname: '',
      mobile: '',
      email: '',
      after_hours: '',
      position: '',
    },
    {
      id: 0,
      info_type: 'Sustainability Manager',
      fullname: '',
      mobile: '',
      email: '',
      after_hours: '',
      position: '',
    },
    {
      id: 0,
      info_type: 'After Hours Contact',
      fullname: '',
      mobile: '',
      after_hours: '',
      position: '',
    },
  ];

  const { values, errors, touched, setFieldValue, setValues, handleSubmit } = useFormik({
    initialValues: {
      fields: InitialValues,
    },
    validationSchema: Yup.object({
      fields: Yup.array().of(
        Yup.object().shape({
          info_type: Yup.string(),
          fullname: Yup.string(),
          mobile: Yup.string().matches(/^[+\d\s-()]+$/, 'Invalid mobile number'),
          email: Yup.string().when('info_type', (info_type, schema) => {
            return info_type !== 'After Hours Contact' ? schema.email('Invalid email') : schema;
          }),
          after_hours: Yup.string(),
          position: Yup.string(),
        })
      ),
    }),
    validate: vals => {
      const errs = {};
      if (!vals.fields[0].info_type) {
        errs.fields = errors.fields || [];
        errs.fields[0] = errors.fields[0] || {};
        errs.fields[0].info_type = 'Type is required';
      }
      if (!vals.fields[0].fullname) {
        errs.fields = errors.fields || [];
        errs.fields[0] = errors.fields[0] || {};
        errs.fields[0].fullname = 'Full name is required';
      }
      if (!vals.fields[0].mobile) {
        errs.fields = errors.fields || [];
        errs.fields[0] = errors.fields[0] || {};
        errs.fields[0].mobile = 'Mobile is required';
      }
      if (!vals.fields[0].email) {
        errs.fields = errors.fields || [];
        errs.fields[0] = errors.fields[0] || {};
        errs.fields[0].email = 'Email is required';
      }
      return errs;
    },
    onSubmit: async _values => {
      setIsLoading(true);
      await updateContactInfo(
        _values.fields.map(field => ({
          ...field,
          id: field.id || null,
          email: field.email || '',
          after_hours: field.after_hours || '',
          position: field.position || '',
        }))
      );
    },
  });

  const handleChange = (e, index) => {
    setFieldValue(`fields[${index}].${e.target.name}`, e.target.value);
  };

  useEffect(() => {
    if (editItem) {
      if (editItem.length >= 5) {
        setValues({
          fields: editItem.slice(0, 5).map(field => ({
            ...field,
            id: field.id || 0,
          })),
        });
      } else if (editItem.length < 5) {
        const fields = editItem.map(field => ({
          ...field,
          id: field.id || 0,
        }));
        setValues({
          fields: fields.concat(InitialValues.slice(fields.length, InitialValues.length)),
        });
      } else if (editItem.length === 0) {
        setValues({
          fields: InitialValues,
        });
      }
    }
  }, [editItem]);

  return (
    <DialogRoot open={isOpenPopup} onClose={() => setIsOpenPopup(false)} closeOnOverlayClick={false}>
      <DialogContent maxW="min(870px, 80%)">
        <DialogHeader borderBottomWidth="1px" fontSize="20px">
          Update Contact Information
        </DialogHeader>
        <DialogCloseTrigger asChild onClick={() => setIsOpenPopup(false)} />
        <Formik>
          <form onSubmit={handleSubmit}>
            <DialogBody padding="22px">
              {values?.fields.map((field, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={index}>
                  <Flex gap="20px" css={{ gap: '20px' }}>
                    <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                      <Field
                        label={`Type ${index === 0 ? '*' : ''}`}
                        invalid={errors?.fields?.[index]?.info_type && touched?.fields?.[index]?.info_type}
                        errorText={errors?.fields?.[index]?.info_type}
                      >
                        <Input
                          name="info_type"
                          value={field.info_type}
                          onChange={e => handleChange(e, index)}
                          disabled={field.info_type === 'After Hours Contact'}
                        />
                      </Field>
                    </Box>
                    <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                      <Field
                        label={`Name ${index === 0 ? '*' : ''}`}
                        invalid={errors?.fields?.[index]?.fullname && touched?.fields?.[index]?.fullname}
                        errorText={errors?.fields?.[index]?.fullname}
                      >
                        <Input name="fullname" value={field.fullname} onChange={e => handleChange(e, index)} />
                      </Field>
                    </Box>
                    <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                      <Field
                        label={`Mobile ${index === 0 ? '*' : ''}`}
                        invalid={errors?.fields?.[index]?.mobile && touched?.fields?.[index]?.mobile}
                        errorText={errors?.fields?.[index]?.mobile}
                      >
                        <Input name="mobile" value={field.mobile} onChange={e => handleChange(e, index)} />
                      </Field>
                    </Box>
                    <Box flexGrow={1} marginBottom="20px" flexBasis="50%">
                      {field.info_type === 'After Hours Contact' ? (
                        <>
                          <Field
                            label="Position"
                            invalid={errors?.fields?.[index]?.position && touched?.fields?.[index]?.position}
                            errorText={errors?.fields?.[index]?.position}
                          >
                            <Input name="position" value={field.position} onChange={e => handleChange(e, index)} />
                          </Field>
                        </>
                      ) : (
                        <>
                          <Field
                            label={`Email ${index === 0 ? '*' : ''}`}
                            invalid={errors?.fields?.[index]?.email && touched?.fields?.[index]?.email}
                            errorText={errors?.fields?.[index]?.email}
                          >
                            <Input name="email" value={field.email} onChange={e => handleChange(e, index)} />
                          </Field>
                        </>
                      )}
                    </Box>
                  </Flex>
                  <Flex gap="20px" css={{ gap: '20px' }}>
                    {field.info_type === 'After Hours Contact' && (
                      <Box flexGrow={1} marginBottom="20px" flexBasis="25%" maxW="25%" paddingRight="20px">
                        <Field
                          label="After Hours Time"
                          invalid={errors?.fields?.[index]?.after_hours && touched?.fields?.[index]?.after_hours}
                          errorText={errors?.fields?.[index]?.after_hours}
                        >
                          <Input name="after_hours" value={field.after_hours} onChange={e => handleChange(e, index)} />
                        </Field>
                      </Box>
                    )}
                  </Flex>
                </Fragment>
              ))}
            </DialogBody>
            <DialogFooter borderTopWidth="1px" padding="16px 22px">
              <Group marginLeft="auto">
                <Button
                  type="button"
                  fontSize="16px"
                  width="126px"
                  height="43px"
                  backgroundColor="gray.200"
                  color="#333"
                  onClick={() => setIsOpenPopup(false)}
                >
                  Close
                </Button>
                &nbsp;
                <Button
                  type="submit"
                  width="126px"
                  height="43px"
                  colorScheme="actionPrimary"
                  marginLeft="10px"
                  fontSize="16px"
                  loadingText="Saving..."
                  loading={isLoading}
                >
                  Save
                </Button>
              </Group>
            </DialogFooter>
          </form>
        </Formik>
      </DialogContent>
    </DialogRoot>
  );
};

UpdateContactInfo.propTypes = {
  isOpenPopup: PropTypes.bool,
  setIsOpenPopup: PropTypes.func,
  estId: PropTypes.number,
  editItem: PropTypes.shape({}) || null,
  updateList: PropTypes.func,
};
export default UpdateContactInfo;
