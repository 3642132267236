import React from 'react';
import { Box, Flex, Tabs } from '@chakra-ui/react';
import { FaChevronDown } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { getTenantFromDomain } from '../../../helpers/tenant';
import Constants from '../../../Constants';
import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from '../../ui/menu';

const tabsConfig = [
  {
    name: 'Establishments',
  },
  {
    name: 'HACCP Letters',
  },
  {
    name: 'Letter of Guarantee',
  },
  {
    name: 'Allergen Statements',
  },
  {
    name: 'Important Contacts',
  },
];

/**
 * Renders a tab component for food safety.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.resourcesTab - The currently selected resources tab.
 * @param {function} props.setResourcesTab - The function to set the selected resources tab.
 * @returns {JSX.Element} The FoodSafetyTabs component.
 */
const FoodSafetyTabs = ({ resourcesTab, setResourcesTab }) => {
  const tenant = getTenantFromDomain();
  return (
    <Tabs.List variant="enclosed" borderBottomWidth="0px">
      <Tabs.Trigger
        key="resources"
        value="resources"
        _focus={{ outline: 'none' }}
        _selected={{ borderColor: 'actionSecondary.default', borderBottomWidth: '2px' }}
        _hover={{ borderColor: 'actionSecondary.default', borderBottomWidth: '2px' }}
        fontWeight="normal"
        width="fit-content"
        fontSize="14px"
        padding="0px"
        height="103px"
        marginRight="10px"
      >
        <MenuRoot width="fit-content">
          <>
            <MenuTrigger
              bgColor="inherit"
              color={
                [Constants.RESOURCE_DOCUMENT_TYPES.btt, Constants.RESOURCE_DOCUMENT_TYPES.beef_bundle].includes(
                  resourcesTab
                )
                  ? 'actionSecondary.default'
                  : 'inherit'
              }
              fontWeight={
                [Constants.RESOURCE_DOCUMENT_TYPES.btt, Constants.RESOURCE_DOCUMENT_TYPES.beef_bundle].includes(
                  resourcesTab
                )
                  ? 'bold'
                  : 'normal'
              }
              height="100%"
              _hover={{
                bgColor: 'inherit',
                color: 'actionSecondary.default',
              }}
              _focus={{
                bgColor: 'inherit',
                outline: '0',
                color: 'actionSecondary.default',
              }}
              _active={{
                bgColor: 'inherit',
                color: 'actionSecondary.default',
              }}
            >
              <Flex alignItems="center">
                <Box mr="1">Resources</Box>
                <FaChevronDown />
              </Flex>
            </MenuTrigger>
            <MenuContent zIndex="98" width="270px" padding="0px" borderRadius="0px">
              <MenuItem
                minHeight="40px"
                outline="none"
                color={resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.btt && 'actionSecondary.default'}
                paddingY="9px"
                paddingLeft="40px"
                borderRightWidth={resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.btt && '3px'}
                borderColor="actionSecondary.default"
                bgColor={resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.btt && 'actionSecondary.active20'}
                _hover={{
                  bgColor: 'actionSecondary.active20',
                  borderRightWidth: '3px',
                }}
                _focus={{ outline: 'none' }}
                onClick={() => setResourcesTab(Constants.RESOURCE_DOCUMENT_TYPES.btt)}
              >
                {tenant.includes('cfc') ? 'Technical Guide' : 'BTT Guide / FMG'}
              </MenuItem>
              <MenuItem
                minHeight="40px"
                outline="none"
                color={resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.beef_bundle && 'actionSecondary.default'}
                paddingY="9px"
                paddingLeft="40px"
                borderRightWidth={resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.beef_bundle && '3px'}
                borderColor="actionSecondary.default"
                bgColor={resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.beef_bundle && 'actionSecondary.active20'}
                _hover={{
                  bgColor: 'actionSecondary.active20',
                  borderRightWidth: '3px',
                }}
                _focus={{ outline: 'none' }}
                onClick={() => setResourcesTab(Constants.RESOURCE_DOCUMENT_TYPES.beef_bundle)}
              >
                {tenant.includes('cfc') ? 'Product Bundle' : 'Beef Bundle'}
              </MenuItem>
            </MenuContent>
          </>
        </MenuRoot>
      </Tabs.Trigger>
      {tabsConfig.map(tab => (
        <Tabs.Trigger
          value={tab.name.replaceAll(' ', '')}
          key={tab.name}
          _focus={{ outline: 'none' }}
          _selected={{ borderColor: 'actionSecondary.default', borderBottomWidth: '2px' }}
          _hover={{ borderColor: 'actionSecondary.default', borderBottomWidth: '2px' }}
          width="162px"
          fontSize="14px"
          padding="0px"
          height="103px"
          marginRight="10px"
          justifyContent="center"
          onClick={() => setResourcesTab(tab.name.replaceAll(' ', ''))}
        >
          {tab.name}
        </Tabs.Trigger>
      ))}
      <Tabs.Trigger
        value="more"
        key="more"
        _focus={{ outline: 'none' }}
        _selected={{ borderColor: 'actionSecondary.default', borderBottomWidth: '2px' }}
        _hover={{ borderColor: 'actionSecondary.default', borderBottomWidth: '2px' }}
        fontWeight="normal"
        width="fit-content"
        fontSize="14px"
        padding="0px"
        height="103px"
        marginRight="10px"
      >
        <MenuRoot width="fit-content">
          <MenuTrigger
            bgColor="inherit"
            color={
              [
                Constants.RESOURCE_DOCUMENT_TYPES.annual_audit_report,
                Constants.RESOURCE_DOCUMENT_TYPES.spec,
                Constants.RESOURCE_DOCUMENT_TYPES.audit_certificate,
                Constants.RESOURCE_DOCUMENT_TYPES.coa_example,
                Constants.RESOURCE_DOCUMENT_TYPES.animal_welfare_audit_report,
                Constants.RESOURCE_DOCUMENT_TYPES.other,
              ].includes(resourcesTab)
                ? 'actionSecondary.default'
                : 'inherit'
            }
            fontWeight={
              [
                Constants.RESOURCE_DOCUMENT_TYPES.annual_audit_report,
                Constants.RESOURCE_DOCUMENT_TYPES.spec,
                Constants.RESOURCE_DOCUMENT_TYPES.audit_certificate,
                Constants.RESOURCE_DOCUMENT_TYPES.coa_example,
                Constants.RESOURCE_DOCUMENT_TYPES.animal_welfare_audit_report,
                Constants.RESOURCE_DOCUMENT_TYPES.other,
              ].includes(resourcesTab)
                ? 'bold'
                : 'normal'
            }
            height="100%"
            _hover={{
              bgColor: 'inherit',
              color: 'actionSecondary.default',
            }}
            _focus={{
              bgColor: 'inherit',
              outline: '0',
              color: 'actionSecondary.default',
            }}
            _active={{
              bgColor: 'inherit',
              color: 'actionSecondary.default',
            }}
          >
            <Flex alignItems="center">
              <Box mr="1">More</Box>
              <FaChevronDown />
            </Flex>
          </MenuTrigger>
          <MenuContent zIndex="98" width="270px" padding="0px" borderRadius="0px">
            <MenuItem
              minHeight="40px"
              outline="none"
              color={
                resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.annual_audit_report && 'actionSecondary.default'
              }
              paddingY="9px"
              paddingLeft="40px"
              borderRightWidth={resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.annual_audit_report && '3px'}
              borderColor="actionSecondary.default"
              bgColor={
                resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.annual_audit_report && 'actionSecondary.active20'
              }
              _hover={{
                bgColor: 'actionSecondary.active20',
                borderRightWidth: '3px',
              }}
              _focus={{ outline: 'none' }}
              onClick={() => setResourcesTab(Constants.RESOURCE_DOCUMENT_TYPES.annual_audit_report)}
            >
              3rd Party Food Safety Audit
            </MenuItem>
            <MenuItem
              minHeight="40px"
              outline="none"
              color={resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.audit_certificate && 'actionSecondary.default'}
              paddingY="9px"
              paddingLeft="40px"
              borderRightWidth={resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.audit_certificate && '3px'}
              borderColor="actionSecondary.default"
              bgColor={
                resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.audit_certificate && 'actionSecondary.active20'
              }
              _hover={{
                bgColor: 'actionSecondary.active20',
                borderRightWidth: '3px',
              }}
              _focus={{ outline: 'none' }}
              onClick={() => setResourcesTab(Constants.RESOURCE_DOCUMENT_TYPES.audit_certificate)}
            >
              3rd Party Food Safety Certificate
            </MenuItem>
            <MenuItem
              minHeight="40px"
              outline="none"
              color={resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.spec && 'actionSecondary.default'}
              paddingY="9px"
              paddingLeft="40px"
              borderRightWidth={resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.spec && '3px'}
              borderColor="actionSecondary.default"
              bgColor={resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.spec && 'actionSecondary.active20'}
              _hover={{
                bgColor: 'actionSecondary.active20',
                borderRightWidth: '3px',
              }}
              _focus={{ outline: 'none' }}
              onClick={() => setResourcesTab(Constants.RESOURCE_DOCUMENT_TYPES.spec)}
            >
              Raw Material Product Specification
            </MenuItem>
            <MenuItem
              minHeight="40px"
              outline="none"
              color={resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.coa_example && 'actionSecondary.default'}
              paddingY="9px"
              paddingLeft="40px"
              borderRightWidth={resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.coa_example && '3px'}
              borderColor="actionSecondary.default"
              bgColor={resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.coa_example && 'actionSecondary.active20'}
              _hover={{
                bgColor: 'actionSecondary.active20',
                borderRightWidth: '3px',
              }}
              _focus={{ outline: 'none' }}
              onClick={() => setResourcesTab(Constants.RESOURCE_DOCUMENT_TYPES.coa_example)}
            >
              Example of COA form
            </MenuItem>
            <MenuItem
              minHeight="40px"
              outline="none"
              color={
                resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.animal_welfare_audit_report &&
                'actionSecondary.default'
              }
              paddingY="9px"
              paddingLeft="40px"
              borderRightWidth={resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.animal_welfare_audit_report && '3px'}
              borderColor="actionSecondary.default"
              bgColor={
                resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.animal_welfare_audit_report &&
                'actionSecondary.active20'
              }
              _hover={{
                bgColor: 'actionSecondary.active20',
                borderRightWidth: '3px',
              }}
              _focus={{ outline: 'none' }}
              onClick={() => setResourcesTab(Constants.RESOURCE_DOCUMENT_TYPES.animal_welfare_audit_report)}
            >
              Animal Welfare Audit Report
            </MenuItem>
            <MenuItem
              minHeight="40px"
              outline="none"
              color={resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.other && 'actionSecondary.default'}
              paddingY="9px"
              paddingLeft="40px"
              borderRightWidth={resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.other && '3px'}
              borderColor="actionSecondary.default"
              bgColor={resourcesTab === Constants.RESOURCE_DOCUMENT_TYPES.other && 'actionSecondary.active20'}
              _hover={{
                bgColor: 'actionSecondary.active20',
                borderRightWidth: '3px',
              }}
              _focus={{ outline: 'none' }}
              onClick={() => setResourcesTab(Constants.RESOURCE_DOCUMENT_TYPES.other)}
            >
              Other Facility Documentation
            </MenuItem>
          </MenuContent>
        </MenuRoot>
      </Tabs.Trigger>
    </Tabs.List>
  );
};

FoodSafetyTabs.propTypes = {
  resourcesTab: PropTypes.string.isRequired,
  setResourcesTab: PropTypes.func.isRequired,
};

export default FoodSafetyTabs;
