import React from 'react';
import { Box, IconButton, Portal } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { RxCrossCircled } from 'react-icons/rx';
import { closeError } from '../../actions/index';

const AppError = ({ error, dispatch }) => {
  return (
    <>
      {error && (
        <Portal>
          <Box
            bg="red.500"
            color="white"
            p="10px 20px"
            className="error-msg alert alert-danger"
            bottom="0"
            position="fixed"
            zIndex={100000}
            margin="0 0 15px 15px"
            pointerEvents="auto"
            maxWidth={{
              base: '300px',
              lg: '450px',
            }}
          >
            <IconButton
              width="24px"
              minWidth="24px"
              height="27px"
              pointerEvents="auto"
              onClick={() => dispatch(closeError(false))}
            >
              <RxCrossCircled />
            </IconButton>
            {error.toString()}
          </Box>
        </Portal>
      )}
    </>
  );
};

AppError.propTypes = {
  error: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default AppError;
