import { Box, Button, Group, Flex, Input, Text, IconButton, createListCollection } from '@chakra-ui/react';
import { Formik, useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { uploadFileToS3Request } from '../../../data-fixtures/basic';
import api from '../../../api';
import { actions } from '../../../reducers/reducer_food_safety_quality';
import countryCodes from '../../../shared/countryCodes';
import { Tooltip } from '@/components/ui/tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { getTenantFromDomain } from '../../../helpers/tenant';
import {
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from '@/components/ui/dialog';
import { Field } from '@/components/ui/field';
import { SelectContent, SelectItem, SelectRoot, SelectTrigger, SelectValueText } from '../../ui/select';
import { toaster } from '@/components/ui/toaster';
import { NativeSelectField, NativeSelectRoot } from '@/components/ui/native-select';

const contactTypeCollection = createListCollection({
  items: [
    { label: 'Primary Contact', value: 'Primary Contact' },
    { label: 'Secondary Contact', value: 'Secondary Contact' },
  ],
});

// const countryCodesCollection = createListCollection({
//   items: countryCodes.map(country => ({ label: `${country.name} (${country.dial_code})`, value: country.dial_code })),
// });

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const AddContact = ({ editItem, isOpenAddPopup, setIsOpenAddPopup }) => {
  const dispatch = useDispatch();
  // const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const { addNewContact, updateContact } = actions;
  const tenant = getTenantFromDomain();
  const domain = tenant.includes('cfc') ? 'CFCO' : 'FMG';

  const constactOfCollection = createListCollection({
    items: [
      { label: domain, value: domain },
      { label: 'Grinder', value: 'Grinder' },
      { label: 'Sponsor', value: 'Sponsor' },
    ],
  });

  const associateToCollection = createListCollection({
    items: [
      { label: 'Tyson', value: 'Tyson' },
      { label: 'TurOSI', value: 'TurOSI' },
      { label: 'ANZCO', value: 'ANZCO' },
    ],
  });

  const validationSchema = Yup.object().shape({
    contact_of: Yup.string().required('Contact For is a required field'),
    name: Yup.string().max(100).required('Name is a required field'),
    role: Yup.string().max(100).required('Role is a required field'),
    contact_type: Yup.string().required('Contact Type is a required field'),
    country_code: Yup.string().required('Country Code is a required field'),
    mobile: Yup.string()
      .min(7, 'Phone number is not valid')
      .max(15, 'Phone number is not valid')
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Number is a required field'),
    email: Yup.string().email('Please enter a valid email address.').max(100).required('Email is a required field'),
    associated_to: Yup.string()
      .nullable()
      .when('contact_of', (contact_of, schema) =>
        contact_of === 'Grinder' || contact_of === 'Sponsor'
          ? schema.required(`${contact_of} is a required field`)
          : schema.notRequired()
      ),
  });

  const addContactHandler = async formValues => {
    try {
      setIsLoading(true);
      let resource;
      let newValues = JSON.parse(JSON.stringify(formValues));

      if (formValues.rs_path instanceof File) {
        const filePath = await uploadFileToS3Request({ file: formValues.rs_path });
        newValues = { ...formValues, rs_path: filePath };
      }

      if (editItem) {
        delete newValues.id;
        resource = await api.patch(`/op-importantcontact/${formValues.id}`, newValues);
      } else {
        resource = await api.post('/op-importantcontacts', newValues);
      }

      const data = await resource.json();
      if (editItem) {
        dispatch(updateContact(data));
      } else {
        dispatch(addNewContact(data));
      }
      setIsOpenAddPopup(false);
      toaster.create({
        title: `Contact ${editItem ? 'Updated' : 'Added'} Successfully`,
        status: 'success',
      });
      setIsLoading(false);
    } catch (error) {
      toaster.create({
        title: error.message,
        status: 'error',
      });
      setIsLoading(false);
    }
  };

  const { errors, touched, values, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: {
      contact_of: editItem ? editItem.contact_of : '',
      name: editItem ? editItem.name : '',
      role: editItem ? editItem.role : '',
      contact_type: editItem ? editItem.contact_type : '',
      country_code: editItem ? editItem.country_code : '',
      mobile: editItem ? editItem.mobile : '',
      email: editItem ? editItem.email : '',
      associated_to: editItem ? editItem.associated_to || '' : '',
    },
    validationSchema,
    onSubmit: addContactHandler,
  });

  useEffect(() => {
    if (editItem) {
      setFieldValue('id', editItem.id);
      setFieldValue('contact_of', editItem.contact_of);
      setFieldValue('associated_to', editItem.associated_to);
      setFieldValue('name', editItem.name);
      setFieldValue('role', editItem.role);
      setFieldValue('contact_type', editItem.contact_type);
      setFieldValue('country_code', editItem.country_code);
      setFieldValue('mobile', editItem.mobile);
      setFieldValue('email', editItem.email);
    }
  }, [editItem]);

  return (
    <DialogRoot open={isOpenAddPopup} onClose={() => setIsOpenAddPopup(false)} closeOnOverlayClick={false}>
      <DialogContent maxW="min(900px, 80%)">
        <DialogHeader borderBottomWidth="1px" fontSize="20px">
          {editItem ? 'Edit' : 'Add'} Contact
        </DialogHeader>
        <DialogCloseTrigger asChild onClick={() => setIsOpenAddPopup(false)} />
        <Formik>
          <form onSubmit={handleSubmit}>
            <DialogBody padding="22px">
              <Box flexGrow={1} marginBottom="20px">
                <Field
                  errorText={errors.contact_of}
                  invalid={errors.contact_of && touched.contact_of}
                  label={
                    <>
                      Contact For&nbsp;
                      <Tooltip content="Select the appropriate contact group" positioning={{ placement: 'right-end' }}>
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                    </>
                  }
                >
                  <SelectRoot
                    collection={constactOfCollection}
                    value={[values.contact_of]}
                    onValueChange={e => setFieldValue('contact_of', e.value[0])}
                  >
                    <SelectTrigger>
                      <SelectValueText placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent zIndex="10000">
                      {constactOfCollection.items.map(opt => (
                        <SelectItem item={opt} key={opt.value}>
                          {opt.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </SelectRoot>
                </Field>
              </Box>

              {values.contact_of === 'Grinder' && (
                <Box flexGrow={1} marginBottom="20px">
                  <Field
                    errorText={errors.associated_to}
                    invalid={errors.associated_to && touched.associated_to}
                    label={
                      <>
                        Grinder&nbsp;
                        <Tooltip content="Select the Grinder" positioning={{ placement: 'right-end' }}>
                          <IconButton
                            width="14px"
                            height="14px"
                            padding="0"
                            minW="auto"
                            borderRadius="50%"
                            color="#878787"
                            variant="unstyled"
                          >
                            <IoInformationCircleOutline size="14px" />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                  >
                    <SelectRoot
                      collection={associateToCollection}
                      value={[values.associated_to]}
                      onValueChange={e => setFieldValue('associated_to', e.value[0])}
                    >
                      <SelectTrigger>
                        <SelectValueText placeholder="Select" />
                      </SelectTrigger>
                      <SelectContent zIndex="10000">
                        {associateToCollection.items.map(opt => (
                          <SelectItem item={opt} key={opt.value}>
                            {opt.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </SelectRoot>
                  </Field>
                </Box>
              )}

              {values.contact_of === 'Sponsor' && (
                <Box flexGrow={1} marginBottom="20px">
                  <Field
                    errorText={errors.associated_to}
                    invalid={errors.associated_to && touched.associated_to}
                    label={
                      <>
                        Sponsor&nbsp;
                        <Tooltip content="Select the Sponsor" positioning={{ placement: 'right-end' }}>
                          <IconButton
                            width="14px"
                            height="14px"
                            padding="0"
                            minW="auto"
                            borderRadius="50%"
                            color="#878787"
                            variant="unstyled"
                          >
                            <IoInformationCircleOutline size="14px" />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                  >
                    <SelectRoot
                      collection={associateToCollection}
                      value={[values.associated_to]}
                      onValueChange={e => setFieldValue('associated_to', e.value[0])}
                    >
                      <SelectTrigger>
                        <SelectValueText placeholder="Select" />
                      </SelectTrigger>
                      <SelectContent zIndex="10000">
                        {associateToCollection.items.map(opt => (
                          <SelectItem item={opt} key={opt.value}>
                            {opt.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </SelectRoot>
                  </Field>
                </Box>
              )}

              <Flex gap="20px" css={{ gap: '20px' }} marginBottom="20px">
                <Box flexGrow={1} flexBasis="50%">
                  <Field
                    label={
                      <>
                        Full Name&nbsp;
                        <Tooltip
                          content="Enter the full name of the contact person"
                          positioning={{ placement: 'right-end' }}
                        >
                          <IconButton
                            width="14px"
                            height="14px"
                            padding="0"
                            minW="auto"
                            borderRadius="50%"
                            color="#878787"
                            variant="unstyled"
                          >
                            <IoInformationCircleOutline size="14px" />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                    errorText={errors.name}
                    invalid={errors.name && touched.name}
                  >
                    <Input name="name" value={values.name} onChange={handleChange} />
                  </Field>
                </Box>
                <Box flexGrow={1} flexBasis="50%">
                  <Field
                    label={
                      <>
                        Role&nbsp;
                        <Tooltip
                          content="Specify the role of the contact person"
                          positioning={{ placement: 'right-end' }}
                        >
                          <IconButton
                            width="14px"
                            height="14px"
                            padding="0"
                            minW="auto"
                            borderRadius="50%"
                            color="#878787"
                            variant="unstyled"
                          >
                            <IoInformationCircleOutline size="14px" />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                    errorText={errors.role}
                    invalid={errors.role && touched.role}
                  >
                    <Input name="role" value={values.role} onChange={handleChange} />
                  </Field>
                </Box>
              </Flex>
              <Flex gap="20px" css={{ gap: '20px' }} marginBottom="20px">
                <Box flexGrow={1} flexBasis="50%">
                  <Field
                    label={
                      <>
                        Contact Type&nbsp;
                        <Tooltip
                          content="Choose the type of contact (e.g., Primary, Secondary)"
                          positioning={{ placement: 'right-end' }}
                        >
                          <IconButton
                            width="14px"
                            height="14px"
                            padding="0"
                            minW="auto"
                            borderRadius="50%"
                            color="#878787"
                            variant="unstyled"
                          >
                            <IoInformationCircleOutline size="14px" />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                    errorText={errors.contact_type}
                    invalid={errors.contact_type && touched.contact_type}
                  >
                    <SelectRoot
                      collection={contactTypeCollection}
                      value={[values.contact_type]}
                      onValueChange={e => setFieldValue('contact_type', e.value[0])}
                    >
                      <SelectTrigger>
                        <SelectValueText />
                      </SelectTrigger>
                      <SelectContent zIndex="10000">
                        {contactTypeCollection.items.map(opt => (
                          <SelectItem item={opt} key={opt.value}>
                            {opt.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </SelectRoot>
                  </Field>
                </Box>
                <Box flexGrow={1} flexBasis="50%">
                  <Field
                    label={
                      <>
                        Country Code&nbsp;
                        <Tooltip
                          content="Select the country code associated with the contact number"
                          positioning={{ placement: 'right-end' }}
                        >
                          <IconButton
                            width="14px"
                            height="14px"
                            padding="0"
                            minW="auto"
                            borderRadius="50%"
                            color="#878787"
                            variant="unstyled"
                          >
                            <IoInformationCircleOutline size="14px" />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                    errorText={errors.country_code}
                    invalid={errors.country_code && touched.country_code}
                  >
                    <NativeSelectRoot
                    // collection={countryCodesCollection}
                    // value={[values.country_code]}
                    // onValueChange={e => setFieldValue('country_code', e.value[0])}
                    >
                      {/* <SelectTrigger>
                        <SelectValueText />
                      </SelectTrigger> */}
                      <NativeSelectField
                        zIndex="10000"
                        placeholder="Select"
                        value={values.country_code}
                        onChange={e => setFieldValue('country_code', e.currentTarget.value)}
                      >
                        {countryCodes.map(country => (
                          <option value={country.dial_code} key={`${country.name} (${country.dial_code})`}>
                            {`${country.name} (${country.dial_code})`}
                          </option>
                        ))}
                      </NativeSelectField>
                    </NativeSelectRoot>
                  </Field>
                </Box>
              </Flex>
              <Flex gap="20px" css={{ gap: '20px' }} marginBottom="20px">
                <Box flexGrow={1} flexBasis="50%">
                  <Field
                    label={
                      <>
                        Contact Number&nbsp;
                        <Tooltip
                          content="Enter the contact person's phone number, including the area code"
                          positioning={{ placement: 'right-end' }}
                        >
                          <IconButton
                            width="14px"
                            height="14px"
                            padding="0"
                            minW="auto"
                            borderRadius="50%"
                            color="#878787"
                            variant="unstyled"
                          >
                            <IoInformationCircleOutline size="14px" />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                    errorText={errors.mobile}
                    invalid={errors.mobile && touched.mobile}
                  >
                    <Input name="mobile" value={values.mobile} onChange={handleChange} />
                  </Field>
                </Box>
                <Box flexGrow={1} flexBasis="50%">
                  <Field
                    label={
                      <>
                        Email Address&nbsp;
                        <Tooltip
                          content="Provide the contact person's email address"
                          positioning={{ placement: 'right-end' }}
                        >
                          <IconButton
                            width="14px"
                            height="14px"
                            padding="0"
                            minW="auto"
                            borderRadius="50%"
                            color="#878787"
                            variant="unstyled"
                          >
                            <IoInformationCircleOutline size="14px" />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                    errorText={errors.email}
                    invalid={errors.email && touched.email}
                  >
                    <Input name="email" value={values.email} onChange={handleChange} />
                  </Field>
                </Box>
              </Flex>
            </DialogBody>
            <DialogFooter borderTopWidth="1px" marginTop="26px" padding="16px 22px">
              <Group marginLeft="auto">
                <Button type="button" width="126px" height="43px" backgroundColor="gray.200" color="#333" onClick={() => setIsOpenAddPopup(false)}>
                  <Text as="p" fontSize="16px" fontWeight="black">
                    Close
                  </Text>
                </Button>
                &nbsp;
                <Button
                  type="submit"
                  width="126px"
                  height="43px"
                  colorScheme="actionPrimary"
                  marginLeft="10px"
                  loadingText="Saving..."
                  loading={isLoading}
                >
                  <Text as="p" fontSize="16px" fontWeight="black">
                    Save
                  </Text>
                </Button>
              </Group>
            </DialogFooter>
          </form>
        </Formik>
      </DialogContent>
    </DialogRoot>
  );
};

AddContact.propTypes = {
  editItem: PropTypes.shape({}) || PropTypes.null,
  isOpenAddPopup: PropTypes.bool.isRequired,
  setIsOpenAddPopup: PropTypes.func.isRequired,
};

export default AddContact;
